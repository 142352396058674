/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPaymentMethodsResponseBody = {
    properties: {
        methods: {
            type: 'array',
            contains: {
                properties: {
                    code: {
                        type: 'string',
                    },
                    date: {
                        type: 'string',
                        description: `Date of payment method creation.`,
                        format: 'Date',
                    },
                    id: {
                        type: 'number',
                        description: `Internal payment method identifier.`,
                        format: 'int64',
                    },
                    paymentMethod: {
                        type: 'PaymentMethod',
                    },
                    provider: {
                        type: 'string',
                        description: `Provider name used to match payment methods with list of active providers from [Get payment methods](#operation/getPaymentMethods) by \`providerName\` field.`,
                    },
                    token: {
                        type: 'string',
                        description: `Payment method token for using subsequent purchase. Use this it as \`token\` field in subsequent calls of [Create order](#operation/createOrder).`,
                    },
                    tokenize: {
                        type: 'boolean',
                        description: `Flag for spreedly card methods which indicates whether user must be asked for cvv before purchase. [Spreedly recache flow](https://docs.spreedly.com/reference/iframe/v1/recache/)
                        `,
                    },
                },
            },
        },
        quickPurchaseSupportedType: {
            type: 'QuickPurchaseSupportedType',
        },
        verificationRequired: {
            type: 'boolean',
            description: `Indicates whether card verification is required or not`,
        },
    },
} as const;
