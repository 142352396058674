/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OfferInboxNotificationInfo = {
    type: 'all-of',
    description: `Details on offer inbox notification event`,
    contains: [{
        type: 'InboxNotificationInfo',
    }, {
        properties: {
            offer: {
                type: 'OfferInfo',
                isRequired: true,
            },
        },
    }],
} as const;
