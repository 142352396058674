/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Trustly = {
    type: 'all-of',
    description: `ACH provider. If you have this object in reply that's mean trustly is enabled on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            id: {
                type: 'string',
                description: `Access ID used for iframe initialization.`,
            },
            merchant: {
                type: 'string',
                description: `Merchant ID used for iframe initialization.`,
            },
            notificationUrl: {
                type: 'string',
                description: `Notification URL used for iframe initialization.`,
            },
        },
    }],
} as const;
