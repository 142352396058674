/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SpreedlyGooglePay = {
    type: 'all-of',
    description: `If you have this object in reply that's mean spreedly google pay card processing is enable on backend side. For the initialization of Google Pay, we need to pass fields from the response to the Google Pay react component.  In our case, we use '@google-pay/button-react'. Data from the response we provide to these fields:  tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            gateway: config?.gateway as string,
            gatewayMerchantId: config?.merchant as string,
        },
    },
    merchantInfo: {
        merchantId: config?.merchantId as string,
        merchantName: config?.merchantName?.replace(/_/g, ' '),
    },
    [For more information about initialization Google Pay button](https://www.npmjs.com/package/@google-pay/button-react), [Spreedly documentation regarding google pay](https://docs.spreedly.com/guides/google-pay/).'
    `,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            gateway: {
                type: 'string',
                description: `Gateway name configuration. For now it is spreedly.`,
            },
            merchant: {
                type: 'string',
                description: `Merchant identifier from google pay admin panel.`,
            },
            merchantId: {
                type: 'string',
                description: `Gateway merchant id. Spreedly environment key.`,
            },
            merchantName: {
                type: 'string',
                description: `Merchant name from google pay admin panel.`,
            },
        },
    }],
} as const;
