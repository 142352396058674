/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CryptoPaymentMethod = {
    type: 'all-of',
    contains: [{
        type: 'PaymentMethod',
    }, {
        properties: {
            wallet: {
                type: 'string',
            },
            currency: {
                type: 'CryptoCurrency',
            },
            network: {
                type: 'CryptoNetwork',
            },
        },
    }],
} as const;
