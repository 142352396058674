/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccountBonusesResponse = {
    properties: {
        daily: {
            type: 'array',
            contains: {
                type: 'DailyBonusInfo',
            },
        },
        manual: {
            type: 'array',
            contains: {
                type: 'ManualBonusInfo',
            },
        },
        invitation: {
            type: 'array',
            contains: {
                type: 'InvitationBonusInfo',
            },
        },
        cashback: {
            type: 'array',
            contains: {
                type: 'CashbackBonusInfo',
            },
        },
        wheels: {
            type: 'array',
            contains: {
                type: 'WheelOfWinnersInfo',
            },
        },
        showCaptcha: {
            type: 'boolean',
        },
    },
} as const;
