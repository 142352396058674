/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Spreedly = {
    type: 'all-of',
    description: `If you have this object in reply that's mean spreedly card processing is enable on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            id: {
                type: 'string',
                description: `For the initialization of Spreedly, we need to pass the id to the initialization function:
                Spreedly.init('id', {
                    'numberEl': 'spreedly-number',
                    'cvvEl' 'spreedly-cvv'
                });
                `,
            },
        },
    }],
} as const;
