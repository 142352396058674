/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Capture3DsPaymentOrderRequestBody = {
    properties: {
        orderTransactionId: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        session: {
            type: 'string',
            description: `SEON device fingerprint`,
            isRequired: true,
        },
        cvvEntered: {
            type: 'boolean',
            description: `Applicable to spreedly card routing purchases only.  If new card tokenization or user has entered cvv after receiving \`err_payment_input_cvv\`.`,
            isRequired: true,
        },
    },
} as const;
