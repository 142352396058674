/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TransactionDetails = {
    properties: {
        isFirstOfferPurchase: {
            type: 'boolean',
            description: `Indicates whether this order is a first offer purchase for this account.`,
        },
        firstOfferPurchaseDate: {
            type: 'string',
            description: `Date of the first offer purchase.`,
            format: 'Date',
        },
        isFirstFiatDeposit: {
            type: 'boolean',
            description: `Indicates whether this order is a first fiat deposit for this account.`,
        },
        firstFiatDepositDate: {
            type: 'string',
            description: `Date of the first deposit.`,
            format: 'Date',
        },
    },
} as const;
