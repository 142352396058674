/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPurchaseHistoryRequestBody = {
    properties: {
        dateRange: {
            type: 'DateRange',
        },
        pageRequest: {
            type: 'PageRequest',
            description: `The optional page details. If not specified, the API returns purchases with default pagination`,
        },
        orderType: {
            type: 'Enum',
        },
    },
} as const;
