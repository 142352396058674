/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CancelRedeemMoneyResponseBody = {
    type: 'one-of',
    contains: [{
        type: 'RedeemItem',
    }],
} as const;
