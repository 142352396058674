/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PurchaseDeclineNotification = {
    description: `This websocket notification is sent after offer is marked as declined after [Create order](#operation/createOrder), in most cases CreateOrderResponse is enough to understand status of order. In most cases it is used in cases when player is redirected back from provider checkout page (e.g Nuvei, Skrill) to get notification if order was declined. In cases when transaction is initiated on our website this notification can be ignored.'
    `,
    properties: {
        provider: {
            type: 'string',
            description: `Payment provider that has processed this transaction.`,
            isRequired: true,
        },
        reason: {
            type: 'string',
            description: `Decline reason for this purchase.`,
        },
        code: {
            type: 'string',
            description: `External transaction reference on the payment provider.`,
        },
        transactionId: {
            type: 'string',
            description: `Unique transaction identifier.`,
            isRequired: true,
        },
        amount: {
            type: 'number',
            description: `Transaction amount for this purchase.`,
        },
        currency: {
            type: 'string',
            description: `Transaction currency for this purchase.`,
        },
        tempToken: {
            type: 'string',
            description: `Token used for payment processing.`,
        },
        errCode: {
            type: 'string',
            description: `Transaction error code.`,
        },
        description: {
            type: 'string',
            description: `Order description.`,
        },
        method: {
            type: 'PurchaseMethod',
            description: `Payment method for a given order.`,
        },
        totalDepositAmount: {
            type: 'string',
            description: `Total deposited amount, offer purchases not included.`,
        },
        rescueProviders: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
