/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Price = {
    properties: {
        amount: {
            type: 'string',
            description: `Transaction amount in local currency.`,
        },
        currency: {
            type: 'string',
            description: `Local user currency.`,
        },
        baseAmount: {
            type: 'string',
            description: `Transaction amount in base currency.`,
        },
        baseCurrency: {
            type: 'string',
            description: `Original currency used for this transaction.`,
        },
    },
} as const;
