/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { Api } from './Api';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AcceptDailyBonusRequest } from './models/AcceptDailyBonusRequest';
export type { AcceptDailyBonusResponse } from './models/AcceptDailyBonusResponse';
export type { AcceptFreeGoldCoinsRequest } from './models/AcceptFreeGoldCoinsRequest';
export type { AcceptFreeGoldCoinsResponse } from './models/AcceptFreeGoldCoinsResponse';
export type { AcceptInvitationBonusRequest } from './models/AcceptInvitationBonusRequest';
export type { AcceptInvitationBonusResponse } from './models/AcceptInvitationBonusResponse';
export type { AcceptManualBonusRequest } from './models/AcceptManualBonusRequest';
export type { AcceptManualBonusResponse } from './models/AcceptManualBonusResponse';
export type { AcceptPersonalJackpotRequest } from './models/AcceptPersonalJackpotRequest';
export type { AcceptPersonalJackpotResponse } from './models/AcceptPersonalJackpotResponse';
export type { AcceptRulesRequest } from './models/AcceptRulesRequest';
export type { AcceptRulesResponse } from './models/AcceptRulesResponse';
export type { AcceptSocialMediaRewardRequest } from './models/AcceptSocialMediaRewardRequest';
export type { AcceptSocialMediaRewardResponse } from './models/AcceptSocialMediaRewardResponse';
export type { AcceptSweepstakeIntroRequest } from './models/AcceptSweepstakeIntroRequest';
export type { AcceptSweepstakeIntroResponse } from './models/AcceptSweepstakeIntroResponse';
export type { AccountBalanceInfo } from './models/AccountBalanceInfo';
export { AccountInfo } from './models/AccountInfo';
export type { AccountInvitations } from './models/AccountInvitations';
export type { AccountJackpotInfo } from './models/AccountJackpotInfo';
export type { AccountJackpotWin } from './models/AccountJackpotWin';
export type { AccountNotificationInfo } from './models/AccountNotificationInfo';
export type { AccountPersonalJackpotWin } from './models/AccountPersonalJackpotWin';
export type { AccountRestrictions } from './models/AccountRestrictions';
export { AccountsMode } from './models/AccountsMode';
export type { ActivateAccountFreeSpinRequest } from './models/ActivateAccountFreeSpinRequest';
export { ActivateAccountFreeSpinResponse } from './models/ActivateAccountFreeSpinResponse';
export type { AddAccountFavouriteGameRequest } from './models/AddAccountFavouriteGameRequest';
export type { AddAccountFavouriteGameResponse } from './models/AddAccountFavouriteGameResponse';
export type { AppliedRules } from './models/AppliedRules';
export type { appName } from './models/appName';
export type { BannerInfo } from './models/BannerInfo';
export type { brandName } from './models/brandName';
export type { CancelGiveawayRequest } from './models/CancelGiveawayRequest';
export { CancelGiveawayResponse } from './models/CancelGiveawayResponse';
export type { CashbackBonusInfo } from './models/CashbackBonusInfo';
export type { ChangePasswordRequest } from './models/ChangePasswordRequest';
export type { ChangePasswordResponse } from './models/ChangePasswordResponse';
export type { ChangeSubscriptionPreferencesRequest } from './models/ChangeSubscriptionPreferencesRequest';
export type { ChangeSubscriptionPreferencesResponse } from './models/ChangeSubscriptionPreferencesResponse';
export type { CountryAndStates } from './models/CountryAndStates';
export { CreateAccountSessionRestrictionRequest } from './models/CreateAccountSessionRestrictionRequest';
export type { CreateAccountSessionRestrictionResponse } from './models/CreateAccountSessionRestrictionResponse';
export type { CreateCoinGiveawayRequest } from './models/CreateCoinGiveawayRequest';
export { CreateCoinGiveawayResponse } from './models/CreateCoinGiveawayResponse';
export type { CreateFreeSpinGiveawayRequest } from './models/CreateFreeSpinGiveawayRequest';
export { CreateFreeSpinGiveawayResponse } from './models/CreateFreeSpinGiveawayResponse';
export type { CreateGiveawayRequest } from './models/CreateGiveawayRequest';
export { CreateGiveawayResponse } from './models/CreateGiveawayResponse';
export type { CreatePostalCodeRequest } from './models/CreatePostalCodeRequest';
export type { CreatePostalCodeResponse } from './models/CreatePostalCodeResponse';
export { Currency } from './models/Currency';
export { CurrencyPolicies } from './models/CurrencyPolicies';
export type { DailyBonusInfo } from './models/DailyBonusInfo';
export type { DeleteAccountRequest } from './models/DeleteAccountRequest';
export type { DeleteAccountResponse } from './models/DeleteAccountResponse';
export type { ErrorResponse } from './models/ErrorResponse';
export type { FinalizeFreeSpinGiveawayRequest } from './models/FinalizeFreeSpinGiveawayRequest';
export { FinalizeFreeSpinGiveawayResponse } from './models/FinalizeFreeSpinGiveawayResponse';
export type { FinalizeGiveawayRequest } from './models/FinalizeGiveawayRequest';
export { FinalizeGiveawayResponse } from './models/FinalizeGiveawayResponse';
export type { FreeSpinInfo } from './models/FreeSpinInfo';
export type { GameLaunchRequest } from './models/GameLaunchRequest';
export type { GameLaunchResponse } from './models/GameLaunchResponse';
export { GameTypes } from './models/GameTypes';
export type { GetAccountBonusesRequest } from './models/GetAccountBonusesRequest';
export type { GetAccountBonusesResponse } from './models/GetAccountBonusesResponse';
export type { GetAccountFreeSpinsRequest } from './models/GetAccountFreeSpinsRequest';
export type { GetAccountFreeSpinsResponse } from './models/GetAccountFreeSpinsResponse';
export type { GetAccountInfoRequest } from './models/GetAccountInfoRequest';
export { GetAccountInfoResponse } from './models/GetAccountInfoResponse';
export type { GetAccountInvitationsRequest } from './models/GetAccountInvitationsRequest';
export type { GetAccountInvitationsResponse } from './models/GetAccountInvitationsResponse';
export type { GetAccountJackpotInfoRequest } from './models/GetAccountJackpotInfoRequest';
export type { GetAccountJackpotInfoResponse } from './models/GetAccountJackpotInfoResponse';
export type { GetAccountMetaRequest } from './models/GetAccountMetaRequest';
export type { GetAccountMetaResponse } from './models/GetAccountMetaResponse';
export type { GetAccountNotificationsRequest } from './models/GetAccountNotificationsRequest';
export type { GetAccountNotificationsResponse } from './models/GetAccountNotificationsResponse';
export type { GetAccountRefererInfoRequest } from './models/GetAccountRefererInfoRequest';
export type { GetAccountRefererInfoResponse } from './models/GetAccountRefererInfoResponse';
export type { GetAccountSettingsRequest } from './models/GetAccountSettingsRequest';
export type { GetAccountSettingsResponse } from './models/GetAccountSettingsResponse';
export type { GetActiveGiveawayByAccountRequest } from './models/GetActiveGiveawayByAccountRequest';
export type { GetActiveGiveawayByAccountResponse } from './models/GetActiveGiveawayByAccountResponse';
export type { GetActiveGiveawayRequest } from './models/GetActiveGiveawayRequest';
export type { GetActiveGiveawayResponse } from './models/GetActiveGiveawayResponse';
export type { GetBannersRequest } from './models/GetBannersRequest';
export type { GetBannersResponse } from './models/GetBannersResponse';
export type { GetFreeGoldCoinsStatusRequest } from './models/GetFreeGoldCoinsStatusRequest';
export type { GetFreeGoldCoinsStatusResponse } from './models/GetFreeGoldCoinsStatusResponse';
export type { GetGiveawayRequest } from './models/GetGiveawayRequest';
export type { GetGiveawayResponse } from './models/GetGiveawayResponse';
export type { GetGiveawaySubscribersNumberRequest } from './models/GetGiveawaySubscribersNumberRequest';
export type { GetGiveawaySubscribersNumberResponse } from './models/GetGiveawaySubscribersNumberResponse';
export type { GetInfluencerInfoRequest } from './models/GetInfluencerInfoRequest';
export type { GetInfluencerInfoResponse } from './models/GetInfluencerInfoResponse';
export type { GetJackpotInfoRequest } from './models/GetJackpotInfoRequest';
export type { GetJackpotInfoResponse } from './models/GetJackpotInfoResponse';
export type { GetJackpotOptInRequest } from './models/GetJackpotOptInRequest';
export type { GetJackpotOptInResponse } from './models/GetJackpotOptInResponse';
export type { GetJackpotTotalsRequest } from './models/GetJackpotTotalsRequest';
export type { GetJackpotTotalsResponse } from './models/GetJackpotTotalsResponse';
export type { GetKYCInfoRequest } from './models/GetKYCInfoRequest';
export type { GetKYCInfoResponse } from './models/GetKYCInfoResponse';
export type { GetLotteryResultsRequest } from './models/GetLotteryResultsRequest';
export type { GetLotteryResultsResponse } from './models/GetLotteryResultsResponse';
export type { GetPersonalJackpotRequest } from './models/GetPersonalJackpotRequest';
export type { GetPersonalJackpotResponse } from './models/GetPersonalJackpotResponse';
export type { GetSignupCountriesAndStatesRequest } from './models/GetSignupCountriesAndStatesRequest';
export type { GetSignupCountriesAndStatesResponse } from './models/GetSignupCountriesAndStatesResponse';
export type { GetSignupCountriesRequest } from './models/GetSignupCountriesRequest';
export type { GetSignupCountriesResponse } from './models/GetSignupCountriesResponse';
export type { GetTemporaryTokenRequest } from './models/GetTemporaryTokenRequest';
export { GetTemporaryTokenResponse } from './models/GetTemporaryTokenResponse';
export type { GetVisitedPromotionsRequest } from './models/GetVisitedPromotionsRequest';
export type { GetVisitedPromotionsResponse } from './models/GetVisitedPromotionsResponse';
export { Giveaway } from './models/Giveaway';
export type { GiveawayPrize } from './models/GiveawayPrize';
export type { GoldCoinGeneratorSettings } from './models/GoldCoinGeneratorSettings';
export type { Influencer } from './models/Influencer';
export type { InGameJackpot } from './models/InGameJackpot';
export type { InGameJackpotsResponse } from './models/InGameJackpotsResponse';
export type { InGameJackpotWinner } from './models/InGameJackpotWinner';
export type { InGameJackpotWinnersResponse } from './models/InGameJackpotWinnersResponse';
export type { InvitationBonusInfo } from './models/InvitationBonusInfo';
export type { InvitationInfo } from './models/InvitationInfo';
export type { InvitationRewardPolicy } from './models/InvitationRewardPolicy';
export type { JackpotAccountFreeContribution } from './models/JackpotAccountFreeContribution';
export { JackpotInfo } from './models/JackpotInfo';
export type { JackpotPreferences } from './models/JackpotPreferences';
export type { JackpotWinnerInfo } from './models/JackpotWinnerInfo';
export type { JsonNode } from './models/JsonNode';
export type { KYCInfo } from './models/KYCInfo';
export { KYCStatus } from './models/KYCStatus';
export type { LegalRule } from './models/LegalRule';
export { LegalRuleSettings } from './models/LegalRuleSettings';
export { LevelInfo } from './models/LevelInfo';
export type { Limit } from './models/Limit';
export type { LocationInfo } from './models/LocationInfo';
export type { LowOnCoinsRequest } from './models/LowOnCoinsRequest';
export type { LowOnCoinsResponse } from './models/LowOnCoinsResponse';
export type { ManualBonusInfo } from './models/ManualBonusInfo';
export type { ObfuscatedString } from './models/ObfuscatedString';
export type { OptInGiveawayRequest } from './models/OptInGiveawayRequest';
export { OptInGiveawayResponse } from './models/OptInGiveawayResponse';
export type { OptOutArbitrationRequest } from './models/OptOutArbitrationRequest';
export { OptOutArbitrationResponse } from './models/OptOutArbitrationResponse';
export type { PasswordEnforcementPolicy } from './models/PasswordEnforcementPolicy';
export type { Permissions } from './models/Permissions';
export type { PersonalInfo } from './models/PersonalInfo';
export type { platform } from './models/platform';
export { ProductInfo } from './models/ProductInfo';
export type { ProductInfoTags } from './models/ProductInfoTags';
export { ProductModes } from './models/ProductModes';
export { Promo } from './models/Promo';
export type { qAppName } from './models/qAppName';
export type { qBrandName } from './models/qBrandName';
export { qPlatform } from './models/qPlatform';
export type { RedeemMoneyPolicy } from './models/RedeemMoneyPolicy';
export type { RemoveAccountFavouriteGameRequest } from './models/RemoveAccountFavouriteGameRequest';
export type { RemoveAccountFavouriteGameResponse } from './models/RemoveAccountFavouriteGameResponse';
export type { RequestorInfo } from './models/RequestorInfo';
export type { ResendEmailVerificationRequest } from './models/ResendEmailVerificationRequest';
export type { ResendEmailVerificationResponse } from './models/ResendEmailVerificationResponse';
export type { ResetPasswordManuallyRequest } from './models/ResetPasswordManuallyRequest';
export type { ResetPasswordManuallyResponse } from './models/ResetPasswordManuallyResponse';
export type { ResetPasswordRequest } from './models/ResetPasswordRequest';
export type { ResetPasswordResponse } from './models/ResetPasswordResponse';
export { RuleType } from './models/RuleType';
export type { SaveJackpotOptInRequest } from './models/SaveJackpotOptInRequest';
export type { SaveJackpotOptInResponse } from './models/SaveJackpotOptInResponse';
export type { SendSignInByTokenEmailRequest } from './models/SendSignInByTokenEmailRequest';
export type { SendSignInByTokenEmailResponse } from './models/SendSignInByTokenEmailResponse';
export type { SendWelcomeEmailRequest } from './models/SendWelcomeEmailRequest';
export type { SendWelcomeEmailResponse } from './models/SendWelcomeEmailResponse';
export type { SetAccountAttributionInfoRequest } from './models/SetAccountAttributionInfoRequest';
export type { SetAccountAttributionInfoResponse } from './models/SetAccountAttributionInfoResponse';
export type { SetAccountInfoRequest } from './models/SetAccountInfoRequest';
export { SetAccountInfoResponse } from './models/SetAccountInfoResponse';
export type { SetAccountModeRequest } from './models/SetAccountModeRequest';
export type { SetAccountModeResponse } from './models/SetAccountModeResponse';
export type { SetKYCInfoRequest } from './models/SetKYCInfoRequest';
export type { SetKYCInfoResponse } from './models/SetKYCInfoResponse';
export type { SetLocationInfoRequest } from './models/SetLocationInfoRequest';
export type { SetLocationInfoResponse } from './models/SetLocationInfoResponse';
export type { SetPushInfoRequest } from './models/SetPushInfoRequest';
export type { SetPushInfoResponse } from './models/SetPushInfoResponse';
export type { SignInManualRequest } from './models/SignInManualRequest';
export type { SignInPermanentRequest } from './models/SignInPermanentRequest';
export type { SignInResponse } from './models/SignInResponse';
export type { SignInSocialRequest } from './models/SignInSocialRequest';
export type { SignOutRequest } from './models/SignOutRequest';
export type { SignOutResponse } from './models/SignOutResponse';
export type { SignUpManualRequest } from './models/SignUpManualRequest';
export type { SignUpResponse } from './models/SignUpResponse';
export type { SignUpSocialRequest } from './models/SignUpSocialRequest';
export type { SignWithGuestRequest } from './models/SignWithGuestRequest';
export type { SpinWheelOfWinnersRequest } from './models/SpinWheelOfWinnersRequest';
export type { SpinWheelOfWinnersResponse } from './models/SpinWheelOfWinnersResponse';
export type { UpdateAccountNotificationStatusRequest } from './models/UpdateAccountNotificationStatusRequest';
export type { UpdateAccountNotificationStatusResponse } from './models/UpdateAccountNotificationStatusResponse';
export type { UpdateKYCInfoRequest } from './models/UpdateKYCInfoRequest';
export type { UpdateKYCInfoResponse } from './models/UpdateKYCInfoResponse';
export type { UpdateLocationStatusRequest } from './models/UpdateLocationStatusRequest';
export { UpdateLocationStatusResponse } from './models/UpdateLocationStatusResponse';
export type { UpdateVisitedPromotionsRequest } from './models/UpdateVisitedPromotionsRequest';
export type { UpdateVisitedPromotionsResponse } from './models/UpdateVisitedPromotionsResponse';
export type { WheelOfWinnersInfo } from './models/WheelOfWinnersInfo';
export type { WheelOfWinnersSection } from './models/WheelOfWinnersSection';
export type { X_Chk } from './models/X_Chk';
export type { X_Client_Coordinates } from './models/X_Client_Coordinates';
export type { X_Message_Id } from './models/X_Message_Id';
export type { X_Timestamp } from './models/X_Timestamp';
export type { xCoordinates } from './models/xCoordinates';
export type { xMessageId } from './models/xMessageId';
export { xStatus } from './models/xStatus';
export type { xStatusText } from './models/xStatusText';
export type { xTook } from './models/xTook';

export { $AcceptDailyBonusRequest } from './schemas/$AcceptDailyBonusRequest';
export { $AcceptDailyBonusResponse } from './schemas/$AcceptDailyBonusResponse';
export { $AcceptFreeGoldCoinsRequest } from './schemas/$AcceptFreeGoldCoinsRequest';
export { $AcceptFreeGoldCoinsResponse } from './schemas/$AcceptFreeGoldCoinsResponse';
export { $AcceptInvitationBonusRequest } from './schemas/$AcceptInvitationBonusRequest';
export { $AcceptInvitationBonusResponse } from './schemas/$AcceptInvitationBonusResponse';
export { $AcceptManualBonusRequest } from './schemas/$AcceptManualBonusRequest';
export { $AcceptManualBonusResponse } from './schemas/$AcceptManualBonusResponse';
export { $AcceptPersonalJackpotRequest } from './schemas/$AcceptPersonalJackpotRequest';
export { $AcceptPersonalJackpotResponse } from './schemas/$AcceptPersonalJackpotResponse';
export { $AcceptRulesRequest } from './schemas/$AcceptRulesRequest';
export { $AcceptRulesResponse } from './schemas/$AcceptRulesResponse';
export { $AcceptSocialMediaRewardRequest } from './schemas/$AcceptSocialMediaRewardRequest';
export { $AcceptSocialMediaRewardResponse } from './schemas/$AcceptSocialMediaRewardResponse';
export { $AcceptSweepstakeIntroRequest } from './schemas/$AcceptSweepstakeIntroRequest';
export { $AcceptSweepstakeIntroResponse } from './schemas/$AcceptSweepstakeIntroResponse';
export { $AccountBalanceInfo } from './schemas/$AccountBalanceInfo';
export { $AccountInfo } from './schemas/$AccountInfo';
export { $AccountInvitations } from './schemas/$AccountInvitations';
export { $AccountJackpotInfo } from './schemas/$AccountJackpotInfo';
export { $AccountJackpotWin } from './schemas/$AccountJackpotWin';
export { $AccountNotificationInfo } from './schemas/$AccountNotificationInfo';
export { $AccountPersonalJackpotWin } from './schemas/$AccountPersonalJackpotWin';
export { $AccountRestrictions } from './schemas/$AccountRestrictions';
export { $AccountsMode } from './schemas/$AccountsMode';
export { $ActivateAccountFreeSpinRequest } from './schemas/$ActivateAccountFreeSpinRequest';
export { $ActivateAccountFreeSpinResponse } from './schemas/$ActivateAccountFreeSpinResponse';
export { $AddAccountFavouriteGameRequest } from './schemas/$AddAccountFavouriteGameRequest';
export { $AddAccountFavouriteGameResponse } from './schemas/$AddAccountFavouriteGameResponse';
export { $AppliedRules } from './schemas/$AppliedRules';
export { $appName } from './schemas/$appName';
export { $BannerInfo } from './schemas/$BannerInfo';
export { $brandName } from './schemas/$brandName';
export { $CancelGiveawayRequest } from './schemas/$CancelGiveawayRequest';
export { $CancelGiveawayResponse } from './schemas/$CancelGiveawayResponse';
export { $CashbackBonusInfo } from './schemas/$CashbackBonusInfo';
export { $ChangePasswordRequest } from './schemas/$ChangePasswordRequest';
export { $ChangePasswordResponse } from './schemas/$ChangePasswordResponse';
export { $ChangeSubscriptionPreferencesRequest } from './schemas/$ChangeSubscriptionPreferencesRequest';
export { $ChangeSubscriptionPreferencesResponse } from './schemas/$ChangeSubscriptionPreferencesResponse';
export { $CountryAndStates } from './schemas/$CountryAndStates';
export { $CreateAccountSessionRestrictionRequest } from './schemas/$CreateAccountSessionRestrictionRequest';
export { $CreateAccountSessionRestrictionResponse } from './schemas/$CreateAccountSessionRestrictionResponse';
export { $CreateCoinGiveawayRequest } from './schemas/$CreateCoinGiveawayRequest';
export { $CreateCoinGiveawayResponse } from './schemas/$CreateCoinGiveawayResponse';
export { $CreateFreeSpinGiveawayRequest } from './schemas/$CreateFreeSpinGiveawayRequest';
export { $CreateFreeSpinGiveawayResponse } from './schemas/$CreateFreeSpinGiveawayResponse';
export { $CreateGiveawayRequest } from './schemas/$CreateGiveawayRequest';
export { $CreateGiveawayResponse } from './schemas/$CreateGiveawayResponse';
export { $CreatePostalCodeRequest } from './schemas/$CreatePostalCodeRequest';
export { $CreatePostalCodeResponse } from './schemas/$CreatePostalCodeResponse';
export { $Currency } from './schemas/$Currency';
export { $CurrencyPolicies } from './schemas/$CurrencyPolicies';
export { $DailyBonusInfo } from './schemas/$DailyBonusInfo';
export { $DeleteAccountRequest } from './schemas/$DeleteAccountRequest';
export { $DeleteAccountResponse } from './schemas/$DeleteAccountResponse';
export { $ErrorResponse } from './schemas/$ErrorResponse';
export { $FinalizeFreeSpinGiveawayRequest } from './schemas/$FinalizeFreeSpinGiveawayRequest';
export { $FinalizeFreeSpinGiveawayResponse } from './schemas/$FinalizeFreeSpinGiveawayResponse';
export { $FinalizeGiveawayRequest } from './schemas/$FinalizeGiveawayRequest';
export { $FinalizeGiveawayResponse } from './schemas/$FinalizeGiveawayResponse';
export { $FreeSpinInfo } from './schemas/$FreeSpinInfo';
export { $GameLaunchRequest } from './schemas/$GameLaunchRequest';
export { $GameLaunchResponse } from './schemas/$GameLaunchResponse';
export { $GameTypes } from './schemas/$GameTypes';
export { $GetAccountBonusesRequest } from './schemas/$GetAccountBonusesRequest';
export { $GetAccountBonusesResponse } from './schemas/$GetAccountBonusesResponse';
export { $GetAccountFreeSpinsRequest } from './schemas/$GetAccountFreeSpinsRequest';
export { $GetAccountFreeSpinsResponse } from './schemas/$GetAccountFreeSpinsResponse';
export { $GetAccountInfoRequest } from './schemas/$GetAccountInfoRequest';
export { $GetAccountInfoResponse } from './schemas/$GetAccountInfoResponse';
export { $GetAccountInvitationsRequest } from './schemas/$GetAccountInvitationsRequest';
export { $GetAccountInvitationsResponse } from './schemas/$GetAccountInvitationsResponse';
export { $GetAccountJackpotInfoRequest } from './schemas/$GetAccountJackpotInfoRequest';
export { $GetAccountJackpotInfoResponse } from './schemas/$GetAccountJackpotInfoResponse';
export { $GetAccountMetaRequest } from './schemas/$GetAccountMetaRequest';
export { $GetAccountMetaResponse } from './schemas/$GetAccountMetaResponse';
export { $GetAccountNotificationsRequest } from './schemas/$GetAccountNotificationsRequest';
export { $GetAccountNotificationsResponse } from './schemas/$GetAccountNotificationsResponse';
export { $GetAccountRefererInfoRequest } from './schemas/$GetAccountRefererInfoRequest';
export { $GetAccountRefererInfoResponse } from './schemas/$GetAccountRefererInfoResponse';
export { $GetAccountSettingsRequest } from './schemas/$GetAccountSettingsRequest';
export { $GetAccountSettingsResponse } from './schemas/$GetAccountSettingsResponse';
export { $GetActiveGiveawayByAccountRequest } from './schemas/$GetActiveGiveawayByAccountRequest';
export { $GetActiveGiveawayByAccountResponse } from './schemas/$GetActiveGiveawayByAccountResponse';
export { $GetActiveGiveawayRequest } from './schemas/$GetActiveGiveawayRequest';
export { $GetActiveGiveawayResponse } from './schemas/$GetActiveGiveawayResponse';
export { $GetBannersRequest } from './schemas/$GetBannersRequest';
export { $GetBannersResponse } from './schemas/$GetBannersResponse';
export { $GetFreeGoldCoinsStatusRequest } from './schemas/$GetFreeGoldCoinsStatusRequest';
export { $GetFreeGoldCoinsStatusResponse } from './schemas/$GetFreeGoldCoinsStatusResponse';
export { $GetGiveawayRequest } from './schemas/$GetGiveawayRequest';
export { $GetGiveawayResponse } from './schemas/$GetGiveawayResponse';
export { $GetGiveawaySubscribersNumberRequest } from './schemas/$GetGiveawaySubscribersNumberRequest';
export { $GetGiveawaySubscribersNumberResponse } from './schemas/$GetGiveawaySubscribersNumberResponse';
export { $GetInfluencerInfoRequest } from './schemas/$GetInfluencerInfoRequest';
export { $GetInfluencerInfoResponse } from './schemas/$GetInfluencerInfoResponse';
export { $GetJackpotInfoRequest } from './schemas/$GetJackpotInfoRequest';
export { $GetJackpotInfoResponse } from './schemas/$GetJackpotInfoResponse';
export { $GetJackpotOptInRequest } from './schemas/$GetJackpotOptInRequest';
export { $GetJackpotOptInResponse } from './schemas/$GetJackpotOptInResponse';
export { $GetJackpotTotalsRequest } from './schemas/$GetJackpotTotalsRequest';
export { $GetJackpotTotalsResponse } from './schemas/$GetJackpotTotalsResponse';
export { $GetKYCInfoRequest } from './schemas/$GetKYCInfoRequest';
export { $GetKYCInfoResponse } from './schemas/$GetKYCInfoResponse';
export { $GetLotteryResultsRequest } from './schemas/$GetLotteryResultsRequest';
export { $GetLotteryResultsResponse } from './schemas/$GetLotteryResultsResponse';
export { $GetPersonalJackpotRequest } from './schemas/$GetPersonalJackpotRequest';
export { $GetPersonalJackpotResponse } from './schemas/$GetPersonalJackpotResponse';
export { $GetSignupCountriesAndStatesRequest } from './schemas/$GetSignupCountriesAndStatesRequest';
export { $GetSignupCountriesAndStatesResponse } from './schemas/$GetSignupCountriesAndStatesResponse';
export { $GetSignupCountriesRequest } from './schemas/$GetSignupCountriesRequest';
export { $GetSignupCountriesResponse } from './schemas/$GetSignupCountriesResponse';
export { $GetTemporaryTokenRequest } from './schemas/$GetTemporaryTokenRequest';
export { $GetTemporaryTokenResponse } from './schemas/$GetTemporaryTokenResponse';
export { $GetVisitedPromotionsRequest } from './schemas/$GetVisitedPromotionsRequest';
export { $GetVisitedPromotionsResponse } from './schemas/$GetVisitedPromotionsResponse';
export { $Giveaway } from './schemas/$Giveaway';
export { $GiveawayPrize } from './schemas/$GiveawayPrize';
export { $GoldCoinGeneratorSettings } from './schemas/$GoldCoinGeneratorSettings';
export { $Influencer } from './schemas/$Influencer';
export { $InGameJackpot } from './schemas/$InGameJackpot';
export { $InGameJackpotsResponse } from './schemas/$InGameJackpotsResponse';
export { $InGameJackpotWinner } from './schemas/$InGameJackpotWinner';
export { $InGameJackpotWinnersResponse } from './schemas/$InGameJackpotWinnersResponse';
export { $InvitationBonusInfo } from './schemas/$InvitationBonusInfo';
export { $InvitationInfo } from './schemas/$InvitationInfo';
export { $InvitationRewardPolicy } from './schemas/$InvitationRewardPolicy';
export { $JackpotAccountFreeContribution } from './schemas/$JackpotAccountFreeContribution';
export { $JackpotInfo } from './schemas/$JackpotInfo';
export { $JackpotPreferences } from './schemas/$JackpotPreferences';
export { $JackpotWinnerInfo } from './schemas/$JackpotWinnerInfo';
export { $JsonNode } from './schemas/$JsonNode';
export { $KYCInfo } from './schemas/$KYCInfo';
export { $KYCStatus } from './schemas/$KYCStatus';
export { $LegalRule } from './schemas/$LegalRule';
export { $LegalRuleSettings } from './schemas/$LegalRuleSettings';
export { $LevelInfo } from './schemas/$LevelInfo';
export { $Limit } from './schemas/$Limit';
export { $LocationInfo } from './schemas/$LocationInfo';
export { $LowOnCoinsRequest } from './schemas/$LowOnCoinsRequest';
export { $LowOnCoinsResponse } from './schemas/$LowOnCoinsResponse';
export { $ManualBonusInfo } from './schemas/$ManualBonusInfo';
export { $ObfuscatedString } from './schemas/$ObfuscatedString';
export { $OptInGiveawayRequest } from './schemas/$OptInGiveawayRequest';
export { $OptInGiveawayResponse } from './schemas/$OptInGiveawayResponse';
export { $OptOutArbitrationRequest } from './schemas/$OptOutArbitrationRequest';
export { $OptOutArbitrationResponse } from './schemas/$OptOutArbitrationResponse';
export { $PasswordEnforcementPolicy } from './schemas/$PasswordEnforcementPolicy';
export { $Permissions } from './schemas/$Permissions';
export { $PersonalInfo } from './schemas/$PersonalInfo';
export { $platform } from './schemas/$platform';
export { $ProductInfo } from './schemas/$ProductInfo';
export { $ProductInfoTags } from './schemas/$ProductInfoTags';
export { $ProductModes } from './schemas/$ProductModes';
export { $Promo } from './schemas/$Promo';
export { $qAppName } from './schemas/$qAppName';
export { $qBrandName } from './schemas/$qBrandName';
export { $qPlatform } from './schemas/$qPlatform';
export { $RedeemMoneyPolicy } from './schemas/$RedeemMoneyPolicy';
export { $RemoveAccountFavouriteGameRequest } from './schemas/$RemoveAccountFavouriteGameRequest';
export { $RemoveAccountFavouriteGameResponse } from './schemas/$RemoveAccountFavouriteGameResponse';
export { $RequestorInfo } from './schemas/$RequestorInfo';
export { $ResendEmailVerificationRequest } from './schemas/$ResendEmailVerificationRequest';
export { $ResendEmailVerificationResponse } from './schemas/$ResendEmailVerificationResponse';
export { $ResetPasswordManuallyRequest } from './schemas/$ResetPasswordManuallyRequest';
export { $ResetPasswordManuallyResponse } from './schemas/$ResetPasswordManuallyResponse';
export { $ResetPasswordRequest } from './schemas/$ResetPasswordRequest';
export { $ResetPasswordResponse } from './schemas/$ResetPasswordResponse';
export { $RuleType } from './schemas/$RuleType';
export { $SaveJackpotOptInRequest } from './schemas/$SaveJackpotOptInRequest';
export { $SaveJackpotOptInResponse } from './schemas/$SaveJackpotOptInResponse';
export { $SendSignInByTokenEmailRequest } from './schemas/$SendSignInByTokenEmailRequest';
export { $SendSignInByTokenEmailResponse } from './schemas/$SendSignInByTokenEmailResponse';
export { $SendWelcomeEmailRequest } from './schemas/$SendWelcomeEmailRequest';
export { $SendWelcomeEmailResponse } from './schemas/$SendWelcomeEmailResponse';
export { $SetAccountAttributionInfoRequest } from './schemas/$SetAccountAttributionInfoRequest';
export { $SetAccountAttributionInfoResponse } from './schemas/$SetAccountAttributionInfoResponse';
export { $SetAccountInfoRequest } from './schemas/$SetAccountInfoRequest';
export { $SetAccountInfoResponse } from './schemas/$SetAccountInfoResponse';
export { $SetAccountModeRequest } from './schemas/$SetAccountModeRequest';
export { $SetAccountModeResponse } from './schemas/$SetAccountModeResponse';
export { $SetKYCInfoRequest } from './schemas/$SetKYCInfoRequest';
export { $SetKYCInfoResponse } from './schemas/$SetKYCInfoResponse';
export { $SetLocationInfoRequest } from './schemas/$SetLocationInfoRequest';
export { $SetLocationInfoResponse } from './schemas/$SetLocationInfoResponse';
export { $SetPushInfoRequest } from './schemas/$SetPushInfoRequest';
export { $SetPushInfoResponse } from './schemas/$SetPushInfoResponse';
export { $SignInManualRequest } from './schemas/$SignInManualRequest';
export { $SignInPermanentRequest } from './schemas/$SignInPermanentRequest';
export { $SignInResponse } from './schemas/$SignInResponse';
export { $SignInSocialRequest } from './schemas/$SignInSocialRequest';
export { $SignOutRequest } from './schemas/$SignOutRequest';
export { $SignOutResponse } from './schemas/$SignOutResponse';
export { $SignUpManualRequest } from './schemas/$SignUpManualRequest';
export { $SignUpResponse } from './schemas/$SignUpResponse';
export { $SignUpSocialRequest } from './schemas/$SignUpSocialRequest';
export { $SignWithGuestRequest } from './schemas/$SignWithGuestRequest';
export { $SpinWheelOfWinnersRequest } from './schemas/$SpinWheelOfWinnersRequest';
export { $SpinWheelOfWinnersResponse } from './schemas/$SpinWheelOfWinnersResponse';
export { $UpdateAccountNotificationStatusRequest } from './schemas/$UpdateAccountNotificationStatusRequest';
export { $UpdateAccountNotificationStatusResponse } from './schemas/$UpdateAccountNotificationStatusResponse';
export { $UpdateKYCInfoRequest } from './schemas/$UpdateKYCInfoRequest';
export { $UpdateKYCInfoResponse } from './schemas/$UpdateKYCInfoResponse';
export { $UpdateLocationStatusRequest } from './schemas/$UpdateLocationStatusRequest';
export { $UpdateLocationStatusResponse } from './schemas/$UpdateLocationStatusResponse';
export { $UpdateVisitedPromotionsRequest } from './schemas/$UpdateVisitedPromotionsRequest';
export { $UpdateVisitedPromotionsResponse } from './schemas/$UpdateVisitedPromotionsResponse';
export { $WheelOfWinnersInfo } from './schemas/$WheelOfWinnersInfo';
export { $WheelOfWinnersSection } from './schemas/$WheelOfWinnersSection';
export { $X_Chk } from './schemas/$X_Chk';
export { $X_Client_Coordinates } from './schemas/$X_Client_Coordinates';
export { $X_Message_Id } from './schemas/$X_Message_Id';
export { $X_Timestamp } from './schemas/$X_Timestamp';
export { $xCoordinates } from './schemas/$xCoordinates';
export { $xMessageId } from './schemas/$xMessageId';
export { $xStatus } from './schemas/$xStatus';
export { $xStatusText } from './schemas/$xStatusText';
export { $xTook } from './schemas/$xTook';

export { AccountApiService } from './services/AccountApiService';
export { AuthApiService } from './services/AuthApiService';
export { CrmService } from './services/CrmService';
export { EmailService } from './services/EmailService';
export { GameHubService } from './services/GameHubService';
export { JackpotsService } from './services/JackpotsService';
export { LaunchGameService } from './services/LaunchGameService';
