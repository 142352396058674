/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TrustlyPaymentMethod = {
    type: 'all-of',
    contains: [{
        type: 'PaymentMethod',
    }, {
        properties: {
            code: {
                type: 'string',
            },
            bankName: {
                type: 'string',
            },
            paymentProviderId: {
                type: 'string',
            },
            accountName: {
                type: 'string',
            },
            accountNumber: {
                type: 'string',
            },
            lastUsageAt: {
                type: 'number',
                format: 'int64',
            },
        },
    }],
} as const;
