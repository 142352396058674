/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccountMetaResponse = {
    properties: {
        createdAt: {
            type: 'string',
            description: `The date and time the record was created`,
            format: 'date-time',
        },
        depositCount: {
            type: 'number',
            format: 'int32',
        },
        fbc: {
            type: 'string',
        },
        initialTrafficSource: {
            type: 'string',
        },
        kycEmailSent: {
            type: 'boolean',
        },
        lastActivity: {
            type: 'string',
            format: 'date-time',
        },
        lastDeposit: {
            type: 'string',
            format: 'date-time',
        },
        lastSignIn: {
            type: 'string',
            format: 'date-time',
        },
        lastSignInCity: {
            type: 'string',
        },
        lastSignInIp: {
            type: 'string',
        },
        lastWithdraw: {
            type: 'string',
            format: 'date-time',
        },
        query: {
            type: 'string',
            description: `Query`,
        },
        referredBy: {
            type: 'string',
        },
        signUp: {
            type: 'string',
            format: 'date-time',
        },
        signUpCity: {
            type: 'string',
        },
        signUpPlatform: {
            type: 'string',
        },
        signUpState: {
            type: 'string',
        },
        welcomeEmailSent: {
            type: 'boolean',
        },
    },
} as const;
