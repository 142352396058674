/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CryptoCurrency {
    USDT = 'USDT',
    USDC = 'USDC',
    ETH = 'ETH',
    BTC = 'BTC',
    LTC = 'LTC',
    BCH = 'BCH',
    TST = 'TST',
    EOS = 'EOS',
    CRO = 'CRO',
    UNI = 'UNI',
    APE = 'APE',
    POL = 'POL',
    XRP = 'XRP',
}
