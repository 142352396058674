/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateAccountSessionRestrictionRequest = {
    properties: {
        restrictionType: {
            type: 'Enum',
            isRequired: true,
        },
        startAt: {
            type: 'string',
            format: 'date-time',
        },
        period: {
            properties: {
                years: {
                    type: 'number',
                    format: 'int32',
                },
                months: {
                    type: 'number',
                    format: 'int32',
                },
                days: {
                    type: 'number',
                    format: 'int32',
                },
                zero: {
                    type: 'boolean',
                },
                chronology: {
                    properties: {
                        calendarType: {
                            type: 'string',
                        },
                        isoBased: {
                            type: 'boolean',
                        },
                        id: {
                            type: 'string',
                        },
                    },
                },
                units: {
                    type: 'array',
                    contains: {
                        properties: {
                            dateBased: {
                                type: 'boolean',
                            },
                            timeBased: {
                                type: 'boolean',
                            },
                            duration: {
                                properties: {
                                    seconds: {
                                        type: 'number',
                                        format: 'int64',
                                    },
                                    zero: {
                                        type: 'boolean',
                                    },
                                    nano: {
                                        type: 'number',
                                        format: 'int32',
                                    },
                                    negative: {
                                        type: 'boolean',
                                    },
                                    positive: {
                                        type: 'boolean',
                                    },
                                },
                            },
                            durationEstimated: {
                                type: 'boolean',
                            },
                        },
                    },
                },
                negative: {
                    type: 'boolean',
                },
            },
        },
        reason: {
            type: 'string',
        },
    },
} as const;
