/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemLimitPolicy = {
    properties: {
        min: {
            type: 'number',
            description: `Min redeem amount per request`,
        },
        max: {
            type: 'number',
            description: `Max redeem amount per request`,
        },
        available: {
            type: 'number',
            description: `Available redeem amount per day. Not empty if daily redeem limit by state applicable for player `,
        },
        state: {
            type: 'string',
            description: `Not empty if daily redeem limit by state applicable for player`,
        },
    },
} as const;
