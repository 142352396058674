/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetRedeemHistoryResponseBody = {
    properties: {
        redeems: {
            type: 'array',
            contains: {
                type: 'RedeemItem',
            },
            isRequired: true,
        },
        totalCount: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
    },
} as const;
