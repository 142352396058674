/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateAccountNotificationStatusRequest = {
    properties: {
        notificationId: {
            type: 'number',
            format: 'int64',
        },
        newStatus: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
