/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetCardVerificationsResponseBody = {
    properties: {
        verificationRequired: {
            type: 'boolean',
            description: `Indicates whether card verification is required or not`,
        },
        cards: {
            type: 'array',
            contains: {
                properties: {
                    fingerprint: {
                        type: 'string',
                        description: `Unique card number identifier, same card will have same fingerprint after any tokenization.`,
                    },
                    cardBin: {
                        type: 'string',
                        description: `First six digits of card number`,
                    },
                    lastFour: {
                        type: 'string',
                        description: `Last four digits of card number`,
                    },
                    status: {
                        type: 'Enum',
                    },
                },
            },
        },
    },
} as const;
