/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CardPaymentMethod = {
    type: 'all-of',
    contains: [{
        type: 'PaymentMethod',
    }, {
        properties: {
            name: {
                type: 'string',
                description: `Cardholder name.`,
            },
            verificationStatus: {
                type: 'string',
                description: `UNVERIFIED,NEEDS_VERIFICATION,VERIFICATION_IN_PROGRESS,VERIFIED`,
            },
            expiryMonth: {
                type: 'number',
                description: `Card expiration month`,
            },
            expiryYear: {
                type: 'number',
                description: `Card expiration year.`,
            },
            maskedCardNumber: {
                type: 'string',
                description: `Card last four.`,
            },
            cardType: {
                type: 'string',
                description: `Card brand: visa, master, amex, discover.`,
            },
            cardIssuer: {
                type: 'string',
            },
            fingerprint: {
                type: 'string',
                description: `Unique card number identifier, same card will have same fingerprint after any tokenization.`,
            },
        },
    }],
} as const;
