/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConfirmPhoneNumberVerificationRequestBody = {
    properties: {
        otp: {
            type: 'string',
            description: `OTP verification code`,
        },
        phoneNumber: {
            type: 'string',
            description: `Phone number for verification`,
            isRequired: true,
        },
        session: {
            type: 'string',
        },
    },
} as const;
