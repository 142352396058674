/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Skrill = {
    type: 'all-of',
    description: `Digital wallet. If you have this object in reply that's mean skrill processing is enable on backend side. On purchase just use redirect url from [Create order](#operation/createOrder)'
    `,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            id: {
                type: 'string',
            },
        },
    }],
} as const;
