/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPaymentOrderResponseBody = {
    properties: {
        amount: {
            type: 'number',
        },
        code: {
            type: 'string',
        },
        createdAt: {
            type: 'string',
            format: 'Date',
        },
        currency: {
            type: 'string',
        },
        id: {
            type: 'number',
            format: 'int64',
        },
        offer: {
            type: 'OfferInfo',
        },
        provider: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        success: {
            type: 'boolean',
        },
        transactionId: {
            type: 'string',
            format: 'uuid',
        },
        type: {
            type: 'string',
        },
        isFirstDeposit: {
            type: 'boolean',
            description: `Use from transactionDetails`,
        },
        zip: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        billingDescriptor: {
            type: 'string',
        },
        failReason: {
            type: 'string',
        },
        method: {
            type: 'PurchaseMethod',
        },
        error: {
            type: 'OrderError',
        },
        transactionDetails: {
            type: 'TransactionDetails',
        },
    },
} as const;
