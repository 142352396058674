/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignInSocialRequest = {
    properties: {
        brandName: {
            type: 'string',
            isRequired: true,
        },
        accessToken: {
            type: 'string',
        },
        accessCode: {
            type: 'string',
        },
        redirectUri: {
            type: 'string',
            format: 'uri',
        },
        lang: {
            type: 'string',
        },
        query: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
        trackingId: {
            type: 'string',
        },
        trackingType: {
            type: 'string',
        },
        timezone: {
            type: 'string',
            isRequired: true,
        },
        fingerprint: {
            type: 'string',
        },
        session: {
            type: 'string',
        },
        doNotRunFraudCheck: {
            type: 'boolean',
        },
        checksum: {
            type: 'string',
        },
        platform: {
            type: 'string',
        },
        locale: {
            type: 'string',
        },
        appName: {
            type: 'string',
        },
    },
} as const;
