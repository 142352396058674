/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetKYCInfoRequest = {
    properties: {
        provider: {
            type: 'string',
        },
        phase: {
            type: 'string',
        },
    },
} as const;
