/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PurchaseMethod = {
    properties: {
        paymentMode: {
            type: 'UserPaymentMode',
        },
        integrationType: {
            type: 'UserIntegrationType',
        },
        cardBrand: {
            type: 'string',
            description: `Relevant only for card payments.`,
        },
        cardNumberMask: {
            type: 'string',
            description: `Optional field that is relevant for card payments.`,
        },
        accountNumberMask: {
            type: 'string',
            description: `Optional field that is relevant for ach payments.`,
        },
        email: {
            type: 'string',
            description: `Optional field that is relevant for e-transfer payments.`,
        },
    },
} as const;
