/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WithdrawMethod = {
    description: `One of objects depending of provider find in 'components/schemas': <br>
    <b>skrill</b>: SkrillWithdrawMethod <br>
    <b>nuvei_mazooma_ach</b>: NuveiMazoomaWithdrawMethod<br>
    <b>masspay_ach</b>: MassPayWithdrawMethod<br>
    <b>prizeout</b>: PrizeoutWithdrawMethod<br>
    <b>trustly</b>: TrustlyWithdrawMethod<br>
    <b>payper</b>: PayperWithdrawMethod<br>
    <b>standard_ach</b>: StandardAchWithdrawMethod<br>
    <b>standard_ca_ach</b>: StandardCanadaAchWithdrawMethod<br>
    <b>standard_bsb_ach</b>: StandardBsbAchWithdrawMethod<br>
    `,
    properties: {
        type: {
            type: 'WithdrawMethodType',
            isRequired: true,
        },
    },
} as const;
