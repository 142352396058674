/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateLocationStatusResponse = {
    locationStatus: UpdateLocationStatusResponse.locationStatus;
};
export namespace UpdateLocationStatusResponse {
    export enum locationStatus {
        OK = 'OK',
        REQUIRED_GPS = 'REQUIRED_GPS',
        RESTRICTED = 'RESTRICTED',
    }
}

