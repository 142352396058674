/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AcceptPersonalJackpotRequest = {
    properties: {
        jackpotId: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        currency: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
