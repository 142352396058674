/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemAuthorizedNotification = {
    description: `This notification is triggered after redeem creation [Create redeem](#operation/redeemMoney)`,
    properties: {
        id: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        provider: {
            type: 'string',
            isRequired: true,
        },
        kyc: {
            type: 'boolean',
        },
        kycStatus: {
            type: 'string',
        },
    },
} as const;
