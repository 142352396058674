/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInboxNotificationsRequestBody } from '../models/GetInboxNotificationsRequestBody';
import type { GetInboxNotificationsResponseBody } from '../models/GetInboxNotificationsResponseBody';
import type { UpdateInboxNotificationRequestBody } from '../models/UpdateInboxNotificationRequestBody';
import type { UpdateInboxNotificationResponseBody } from '../models/UpdateInboxNotificationResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Updates inbox notification status.
     * FE should call this method to update status of notification returned in [Get inbox notifications](#operation/getInboxNotifications).
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns UpdateInboxNotificationResponseBody DeletePaymentMethodResponse
     * @throws ApiError
     */
    public updateInboxNotification(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: UpdateInboxNotificationRequestBody,
    ): CancelablePromise<UpdateInboxNotificationResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/UpdateInboxNotificationRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns list of inbox notifications available
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetInboxNotificationsResponseBody GetInboxNotificationsResponse
     * @throws ApiError
     */
    public getInboxNotifications(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetInboxNotificationsRequestBody,
    ): CancelablePromise<GetInboxNotificationsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetInboxNotificationsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
