/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreatePayperPaymentMethodResponseBody = {
    properties: {
        token: {
            type: 'string',
            description: `Payment method token for using subsequent purchase. Use this it as \`token\` field in subsequent calls of [Create order](#operation/createOrder).`,
        },
    },
} as const;
