/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PayperWithdrawMethod = {
    type: 'all-of',
    contains: [{
        type: 'WithdrawMethod',
    }, {
        properties: {
            email: {
                type: 'string',
            },
            phone: {
                type: 'string',
            },
        },
    }],
} as const;
