/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateRange } from './DateRange';
import type { PageRequest } from './PageRequest';
export type GetRedeemsRequestBody = {
    dateRange?: DateRange;
    /**
     * The optional page details. If not specified, the API returns purchases with default pagination
     */
    pageRequest?: PageRequest;
    /**
     * SC redeems are requested with SC currency. Fiat redeems are requested with fiat currency (e.g. USD). Lists all redemptions if the type is not specified
     */
    redeemType?: GetRedeemsRequestBody.redeemType;
};
export namespace GetRedeemsRequestBody {
    /**
     * SC redeems are requested with SC currency. Fiat redeems are requested with fiat currency (e.g. USD). Lists all redemptions if the type is not specified
     */
    export enum redeemType {
        SC_REDEEM = 'sc_redeem',
        FIAT_REDEEM = 'fiat_redeem',
    }
}

