/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AccountApiService } from './services/AccountApiService';
import { AuthApiService } from './services/AuthApiService';
import { CrmService } from './services/CrmService';
import { EmailService } from './services/EmailService';
import { GameHubService } from './services/GameHubService';
import { JackpotsService } from './services/JackpotsService';
import { LaunchGameService } from './services/LaunchGameService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Api {
    public readonly accountApi: AccountApiService;
    public readonly authApi: AuthApiService;
    public readonly crm: CrmService;
    public readonly email: EmailService;
    public readonly gameHub: GameHubService;
    public readonly jackpots: JackpotsService;
    public readonly launchGame: LaunchGameService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.accountApi = new AccountApiService(this.request);
        this.authApi = new AuthApiService(this.request);
        this.crm = new CrmService(this.request);
        this.email = new EmailService(this.request);
        this.gameHub = new GameHubService(this.request);
        this.jackpots = new JackpotsService(this.request);
        this.launchGame = new LaunchGameService(this.request);
    }
}

