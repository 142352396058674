/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InboxNotificationInfo = {
    description: `Details on inbox notification event`,
    properties: {
        type: {
            type: 'InboxNotificationType',
            isRequired: true,
        },
        notificationToken: {
            type: 'string',
            description: `Unique identifier. Used in [Update notification](#operation/updateInboxNotification)`,
            isRequired: true,
        },
        status: {
            type: 'Enum',
            isRequired: true,
        },
        createdAt: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        expiresAt: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
