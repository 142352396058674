/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CryptoPurchaseData = {
    properties: {
        amount: {
            type: 'number',
        },
        wallet: {
            type: 'string',
        },
        txHash: {
            type: 'string',
        },
        currency: {
            type: 'CryptoCurrencyData',
        },
        network: {
            type: 'CryptoNetworkData',
        },
    },
} as const;
