/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SpreedlyApplePay = {
    type: 'all-of',
    description: `If you have this object in reply that's mean spreedly apple pay card processing is enable on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            merchantId: {
                type: 'string',
                description: `For the initialization of Apple Pay, we need to pass the merchantId to the initialization function: window.ApplePaySession.canMakePayments(merchantId).`,
            },
        },
    }],
} as const;
