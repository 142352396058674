/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameLaunchRequest } from '../models/GameLaunchRequest';
import type { GameLaunchResponse } from '../models/GameLaunchResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LaunchGameService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a URL to launch the game
     * @param operator Operator (brand name)
     * @param provider Game provider
     * @param requestBody
     * @returns GameLaunchResponse Returned a game URL
     * @throws ApiError
     */
    public launch(
        operator: string,
        provider: string,
        requestBody: GameLaunchRequest,
    ): CancelablePromise<GameLaunchResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/launch/{operator}/{provider}',
            path: {
                'operator': operator,
                'provider': provider,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Forbidden`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
