/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GiveawayPrize } from './GiveawayPrize';
export type Giveaway = {
    code?: string;
    status?: Giveaway.status;
    gcPrize?: number;
    scPrize?: number;
    winnersNumber?: number;
    subscribersNumber?: number;
    createdAt?: number;
    endedAt?: number;
    prize?: Record<string, GiveawayPrize>;
};
export namespace Giveaway {
    export enum status {
        ACTIVE = 'ACTIVE',
        CLOSED = 'CLOSED',
        CANCELED = 'CANCELED',
        UNRECOGNIZED = 'UNRECOGNIZED',
    }
}

