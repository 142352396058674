/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OfferPurchaseNotification = {
    description: `This websocket notification is sent after offer is marked as successful [Create order](#operation/createOrder), in most cases CreateOrderResponse is enough to understand status of order. In most cases it is used in cases when player is redirected back from provider checkout page (e.g Nuvei, Skrill) to get notification if order was successful. In cases when transaction is initiated on our website this notification can be ignored.
    `,
    properties: {
        offer: {
            type: 'OfferInfo',
            isRequired: true,
        },
        transactionId: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        provider: {
            type: 'string',
            isRequired: true,
        },
        currency: {
            type: 'string',
            isRequired: true,
        },
        firstDeposit: {
            type: 'string',
            isRequired: true,
            format: 'Date',
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        isFirstDeposit: {
            type: 'boolean',
            isRequired: true,
        },
        city: {
            type: 'string',
        },
        zip: {
            type: 'string',
        },
        billingDescriptor: {
            type: 'string',
        },
        method: {
            type: 'PurchaseMethod',
        },
    },
} as const;
