/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InGameJackpotWinner = {
    properties: {
        jackpotId: {
            type: 'string',
        },
        playerId: {
            type: 'string',
        },
        date: {
            type: 'string',
        },
        tier: {
            type: 'string',
        },
        payoutStatus: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
        amount: {
            type: 'string',
        },
        gameId: {
            type: 'string',
        },
        currency: {
            type: 'string',
        },
    },
} as const;
