/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTransactionLimitRequestBody } from '../models/GetTransactionLimitRequestBody';
import type { GetTransactionLimitResponseBody } from '../models/GetTransactionLimitResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LimitService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Transaction limits.
     * Returns transaction limits for a given operation (deposit or redeem).
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetTransactionLimitResponseBody GetTransactionLimitResponse
     * @throws ApiError
     */
    public getTransactionLimitRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetTransactionLimitRequestBody,
    ): CancelablePromise<GetTransactionLimitResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetTransactionLimitRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
