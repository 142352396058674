/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GameTypes {
    BACCARAT = 'baccarat',
    BACCARAT_NEW = 'baccarat_new',
    BINGO = 'bingo',
    BLACKJACK = 'blackjack',
    CASINO = 'casino',
    CASINO_GAMES = 'casino_games',
    CLASSIC_SLOTS = 'classic_slots',
    KENO = 'keno',
    LOTTERY = 'lottery',
    ROULETTE = 'roulette',
    SCRATCH_CARD = 'scratch_card',
    SLOTS = 'slots',
    TABLE = 'table',
    TABLE_GAMES = 'table_games',
    VIDEO_POKER = 'video_poker',
    VIDEO_SLOTS = 'video_slots',
}
