/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserIntegrationType {
    SKRILL = 'skrill',
    NUVEI_MAZOOMA_ACH = 'nuvei_mazooma_ach',
    APPLE_IN_APP = 'apple_in_app',
    TRUSTLY = 'trustly',
    PAYPER = 'payper',
    CRYPTO = 'crypto',
}
