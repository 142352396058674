/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InGameJackpotsResponse } from '../models/InGameJackpotsResponse';
import type { InGameJackpotWinnersResponse } from '../models/InGameJackpotWinnersResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class JackpotsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of jackpots
     * @param operator Operator (brand name)
     * @param provider Game provider
     * @param currency
     * @param gameId
     * @returns InGameJackpotsResponse Returned a list of jackpots
     * @throws ApiError
     */
    public jackpots(
        operator: string,
        provider: string,
        currency?: string,
        gameId?: string,
    ): CancelablePromise<InGameJackpotsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/jackpots/{operator}/{provider}',
            path: {
                'operator': operator,
                'provider': provider,
            },
            query: {
                'currency': currency,
                'gameId': gameId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }
    /**
     * Get a list of jackpot winners
     * @param operator Operator (brand name)
     * @param provider Game provider
     * @param startDate
     * @param endDate
     * @param currency
     * @param jackpotId
     * @returns InGameJackpotWinnersResponse Returned a list of jackpot winners
     * @throws ApiError
     */
    public winners(
        operator: string,
        provider: string,
        startDate: string,
        endDate: string,
        currency?: string,
        jackpotId?: string,
    ): CancelablePromise<InGameJackpotWinnersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/jackpots/winners/{operator}/{provider}',
            path: {
                'operator': operator,
                'provider': provider,
            },
            query: {
                'currency': currency,
                'jackpotId': jackpotId,
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }
}
