/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WithdrawMethodObject = {
    properties: {
        code: {
            type: 'string',
            description: `Unique code of redeem payment method. Must be used for subsequent operations`,
        },
        withdrawMethod: {
            type: 'WithdrawMethod',
            isRequired: true,
        },
    },
} as const;
