/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StandardAchWithdrawMethod = {
    type: 'all-of',
    contains: [{
        type: 'WithdrawMethod',
    }, {
        properties: {
            bankAccountType: {
                type: 'string',
                isRequired: true,
            },
            bankAccountNumber: {
                type: 'string',
                isRequired: true,
            },
            bankAccountRoutingNumber: {
                type: 'string',
                isRequired: true,
            },
        },
    }],
} as const;
