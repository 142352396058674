/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccountSettingsResponse = {
    properties: {
        passwordPolicy: {
            type: 'PasswordEnforcementPolicy',
        },
        sweepstakeRedeemPolicy: {
            type: 'RedeemMoneyPolicy',
        },
        fiatRedeemPolicy: {
            type: 'RedeemMoneyPolicy',
        },
        nonMonetaryRedeemPolicy: {
            type: 'RedeemMoneyPolicy',
        },
    },
} as const;
