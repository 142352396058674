/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignUpManualRequest = {
    properties: {
        brandName: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        password: {
            type: 'ObfuscatedString',
            isRequired: true,
        },
        query: {
            type: 'string',
        },
        lang: {
            type: 'string',
            isRequired: true,
        },
        timezone: {
            type: 'string',
            isRequired: true,
        },
        fingerprint: {
            type: 'string',
        },
        session: {
            type: 'string',
        },
        trackingId: {
            type: 'string',
        },
        trackingType: {
            type: 'string',
        },
        doNotRunFraudCheck: {
            type: 'boolean',
        },
        checksum: {
            type: 'string',
        },
        locale: {
            type: 'string',
        },
        firstName: {
            type: 'string',
        },
        lastName: {
            type: 'string',
        },
        birthDate: {
            type: 'string',
            format: 'date',
        },
        state: {
            type: 'string',
        },
        profilePhoto: {
            type: 'string',
        },
        pushToken: {
            type: 'string',
        },
        advertisingId: {
            type: 'string',
        },
        referrer: {
            type: 'string',
        },
        doNotSendEmails: {
            type: 'boolean',
        },
        doNotSendPushes: {
            type: 'boolean',
        },
        doNotSendSMS: {
            type: 'boolean',
        },
        phoneNumber: {
            type: 'string',
        },
        address: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
        referredByReg: {
            type: 'string',
        },
        referredBy: {
            type: 'string',
        },
        appName: {
            type: 'string',
        },
    },
} as const;
