/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelRedeemMoneyRequestBody } from '../models/CancelRedeemMoneyRequestBody';
import type { CancelRedeemMoneyResponseBody } from '../models/CancelRedeemMoneyResponseBody';
import type { CaptureRedeemOptionRequestBody } from '../models/CaptureRedeemOptionRequestBody';
import type { CaptureRedeemOptionResponseBody } from '../models/CaptureRedeemOptionResponseBody';
import type { GetCurrencyRateRequestBody } from '../models/GetCurrencyRateRequestBody';
import type { GetCurrencyRateResponseBody } from '../models/GetCurrencyRateResponseBody';
import type { GetPendingRedeemCountRequestBody } from '../models/GetPendingRedeemCountRequestBody';
import type { GetPendingRedeemCountResponseBody } from '../models/GetPendingRedeemCountResponseBody';
import type { GetRedeemHistoryRequestBody } from '../models/GetRedeemHistoryRequestBody';
import type { GetRedeemHistoryResponseBody } from '../models/GetRedeemHistoryResponseBody';
import type { GetRedeemMoneyHistoryRequestBody } from '../models/GetRedeemMoneyHistoryRequestBody';
import type { GetRedeemMoneyHistoryResponseBody } from '../models/GetRedeemMoneyHistoryResponseBody';
import type { GetRedeemsRequestBody } from '../models/GetRedeemsRequestBody';
import type { GetRedeemsResponseBody } from '../models/GetRedeemsResponseBody';
import type { GetWithdrawMethodsRequestBody } from '../models/GetWithdrawMethodsRequestBody';
import type { GetWithdrawMethodsResponseBody } from '../models/GetWithdrawMethodsResponseBody';
import type { GetWithdrawSettingsRequestBody } from '../models/GetWithdrawSettingsRequestBody';
import type { GetWithdrawSettingsResponseBody } from '../models/GetWithdrawSettingsResponseBody';
import type { RedeemMoneyRequestBody } from '../models/RedeemMoneyRequestBody';
import type { RedeemMoneyResponseBody } from '../models/RedeemMoneyResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RedeemService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns list redeem methods.
     * Returns list of saved redeem methods that can be reused for redemptions purchases.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetWithdrawMethodsResponseBody GetWithdrawMethodsResponse
     * @throws ApiError
     */
    public getWithdrawMethods(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetWithdrawMethodsRequestBody,
    ): CancelablePromise<GetWithdrawMethodsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetWithdrawMethodsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Start redeem creation.
     * Capturing of redeem option is used for trustly only.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CaptureRedeemOptionResponseBody CaptureRedeemOptionResponse
     * @throws ApiError
     */
    public captureRedeemOption(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: CaptureRedeemOptionRequestBody,
    ): CancelablePromise<CaptureRedeemOptionResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CaptureRedeemOptionRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create redeem.
     * This call is used for all subsequent redemptions using saved methods  or for those provider where redeem is initiated on our web page
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns RedeemMoneyResponseBody RedeemMoneyResponse
     * @throws ApiError
     */
    public redeemMoney(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: RedeemMoneyRequestBody,
    ): CancelablePromise<RedeemMoneyResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/RedeemMoneyRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cancel redeem.
     * After redeem creation if it wasn't locked by customer support user can cancel it.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CancelRedeemMoneyResponseBody CancelRedeemMoneyResponse
     * @throws ApiError
     */
    public cancelRedeemMoney(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: CancelRedeemMoneyRequestBody,
    ): CancelablePromise<CancelRedeemMoneyResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CancelRedeemMoneyRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * Get redeem money history.
     * Returns redemption money history based on some filters. Please migrate to GetRedeemsRequest
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetRedeemMoneyHistoryResponseBody GetRedeemMoneyHistoryResponse
     * @throws ApiError
     */
    public getRedeemMoneyHistory(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetRedeemMoneyHistoryRequestBody,
    ): CancelablePromise<GetRedeemMoneyHistoryResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetRedeemMoneyHistoryRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * Get redeem history.
     * Returns redemption history based on some filters with pagination. Please migrate to GetRedeemsRequest.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetRedeemHistoryResponseBody GetRedeemHistoryResponse
     * @throws ApiError
     */
    public getRedeemHistory(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetRedeemHistoryRequestBody,
    ): CancelablePromise<GetRedeemHistoryResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetRedeemHistoryRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns list of available redeem providers and limit policy.
     * Returns list of redeem providers and redeem limit policy(per day, daily per state) by provider configured in DB.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetWithdrawSettingsResponseBody GetWithdrawSettingsResponse
     * @throws ApiError
     */
    public getWithdrawSettings(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetWithdrawSettingsRequestBody,
    ): CancelablePromise<GetWithdrawSettingsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetWithdrawSettingsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns data about pending redeems.
     * Returns the number of pending redeems and their total amount.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPendingRedeemCountResponseBody GetPendingRedeemCountResponse
     * @throws ApiError
     */
    public getPendingRedeemCount(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPendingRedeemCountRequestBody,
    ): CancelablePromise<GetPendingRedeemCountResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPendingRedeemCountRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns FX currency rate
     * Returns FX rate for player account currency
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetCurrencyRateResponseBody GetCurrencyRateResponse
     * @throws ApiError
     */
    public getCurrencyRate(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetCurrencyRateRequestBody,
    ): CancelablePromise<GetCurrencyRateResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetCurrencyRateRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get redeem history.
     * Returns redeem history based on some filters with pagination.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetRedeemsResponseBody GetRedeemsResponse
     * @throws ApiError
     */
    public getRedeemsRequest(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetRedeemsRequestBody,
    ): CancelablePromise<GetRedeemsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetRedeemsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
