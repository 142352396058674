/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StandardAch = {
    type: 'all-of',
    description: `Ach provider. If you have this object in reply that's mean standard ach option is enabled on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }],
} as const;
