/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateAccountSessionRestrictionRequest = {
    restrictionType: CreateAccountSessionRestrictionRequest.restrictionType;
    startAt?: string;
    period?: {
        years?: number;
        months?: number;
        days?: number;
        zero?: boolean;
        chronology?: {
            calendarType?: string;
            isoBased?: boolean;
            id?: string;
        };
        units?: Array<{
            dateBased?: boolean;
            timeBased?: boolean;
            duration?: {
                seconds?: number;
                zero?: boolean;
                nano?: number;
                negative?: boolean;
                positive?: boolean;
            };
            durationEstimated?: boolean;
        }>;
        negative?: boolean;
    };
    reason?: string;
};
export namespace CreateAccountSessionRestrictionRequest {
    export enum restrictionType {
        SELF_EXCLUSION = 'SELF_EXCLUSION',
        COOL_OFF = 'COOL_OFF',
    }
}

