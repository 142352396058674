/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TransactionLimit = {
    properties: {
        operation: {
            type: 'PaymentOperation',
            description: `Type of the payment operation`,
            isRequired: true,
        },
        currency: {
            type: 'Currency',
            description: `Transaction currency`,
            isRequired: true,
        },
        min: {
            type: 'number',
        },
        max: {
            type: 'number',
        },
    },
} as const;
