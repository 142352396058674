/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CryptoNetwork {
    BTC = 'BTC',
    ETH = 'ETH',
    BNB = 'BNB',
    LTC = 'LTC',
    TRX = 'TRX',
    POLYGON = 'POLYGON',
    SOL = 'SOL',
    BCH = 'BCH',
    AVAX = 'AVAX',
    TETH = 'TETH',
}
