/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetLotteryResultsResponse = {
    properties: {
        dateFetched: {
            type: 'string',
            format: 'date',
        },
        lotteryResults: {
            type: 'JsonNode',
        },
    },
} as const;
