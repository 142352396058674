/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetTemporaryTokenResponse = {
    token: string;
    subject: string;
    country: string;
    lang: string;
    counterparty: GetTemporaryTokenResponse.counterparty;
};
export namespace GetTemporaryTokenResponse {
    export enum counterparty {
        INTERNAL = 'internal',
        PRIZEOUT = 'prizeout',
        NETENT = 'netent',
        MARKORTECH = 'markortech',
        PRAGMATIC = 'pragmatic',
        RELAX = 'relax',
        PRAGMATIC_BINGO = 'pragmatic_bingo',
        PATRIANNA = 'patrianna',
    }
}

