/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPersonalJackpotResponse = {
    properties: {
        currency: {
            type: 'string',
        },
        amount: {
            type: 'string',
        },
        endAt: {
            type: 'string',
            format: 'date-time',
        },
        createdAt: {
            type: 'string',
            format: 'date-time',
        },
        period: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
