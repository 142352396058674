/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Nuvei = {
    type: 'all-of',
    description: `ACH provider. Iframe initialization not needed.  On purchase just use redirect url from [Create order](#operation/createOrder). On withdraw just pass ACH data to [Redeem money](#operation/redeemMoney)
    `,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            id: {
                type: 'string',
            },
        },
    }],
} as const;
