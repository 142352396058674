/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $JackpotWinnerInfo = {
    properties: {
        currency: {
            type: 'string',
        },
        winnerFirstName: {
            type: 'string',
        },
        winnerLastName: {
            type: 'string',
        },
        code: {
            type: 'string',
        },
        amount: {
            type: 'number',
        },
        spinAmount: {
            type: 'number',
        },
        spinCurrency: {
            type: 'string',
        },
        gameTitle: {
            type: 'string',
        },
        winAt: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
