/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenizeApplePayRequestBody } from '../models/TokenizeApplePayRequestBody';
import type { TokenizeApplePayResponseBody } from '../models/TokenizeApplePayResponseBody';
import type { ValidateApplePayMerchantRequestBody } from '../models/ValidateApplePayMerchantRequestBody';
import type { ValidateApplePayMerchantResponseBody } from '../models/ValidateApplePayMerchantResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ApplepayService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Validate applepay merhcant.
     * This call is part of applepay flow, when tokenizing card using apple iframe. You need validation validationResponse from reply to call completeMerchantValidation  on validate merchant callback in iframe.
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns ValidateApplePayMerchantResponseBody ValidateApplePayMerchantResponse
     * @throws ApiError
     */
    public validateApplePayMerchant(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: ValidateApplePayMerchantRequestBody,
    ): CancelablePromise<ValidateApplePayMerchantResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/ValidateApplePayMerchantRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Tokenizing of apple pay method.
     * After validating merchant in payment authorized callback make tokenize call with applepayToken.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns TokenizeApplePayResponseBody TokenizeApplePayResponse
     * @throws ApiError
     */
    public tokenizeApplePay(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: TokenizeApplePayRequestBody,
    ): CancelablePromise<TokenizeApplePayResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/TokenizeApplePayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
