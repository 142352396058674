/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccountRefererInfoResponse = {
    properties: {
        name: {
            type: 'string',
        },
        profilePhotoUrl: {
            type: 'string',
        },
    },
} as const;
