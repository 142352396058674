/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Capture3DsPaymentOrderResponseBody = {
    properties: {
        error: {
            type: 'OrderError',
        },
    },
} as const;
