/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OfferRefundNotification = {
    properties: {
        offer: {
            type: 'OfferInfo',
            isRequired: true,
        },
        transactionId: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        provider: {
            type: 'string',
            isRequired: true,
        },
        currency: {
            type: 'string',
            isRequired: true,
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
