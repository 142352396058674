/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NuveiMazoomaWithdrawMethod = {
    type: 'all-of',
    contains: [{
        type: 'WithdrawMethod',
    }, {
        properties: {
            userPaymentOptionId: {
                type: 'string',
            },
            fiAccountNumber: {
                type: 'string',
            },
            fiAccountType: {
                type: 'string',
            },
            fiRouting: {
                type: 'string',
            },
            fiName: {
                type: 'string',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            addr: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
            state: {
                type: 'string',
            },
            zip: {
                type: 'string',
            },
            country: {
                type: 'string',
            },
            dobMonth: {
                type: 'string',
            },
            dobDay: {
                type: 'string',
            },
            dobYear: {
                type: 'string',
            },
        },
    }],
} as const;
