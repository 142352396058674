/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetKYCInfoResponse = {
    properties: {
        kyc: {
            type: 'string',
            isRequired: true,
        },
        reason: {
            type: 'string',
        },
        status: {
            type: 'KYCStatus',
            isRequired: true,
        },
        allowNextUpload: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
    },
} as const;
