/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ValidateApplePayMerchantRequestBody = {
    properties: {
        validationUrl: {
            type: 'string',
            description: `Apple pay validation url is coming after apple pay tokenization using apple native iframe.`,
            isRequired: true,
        },
        homePageUrl: {
            type: 'string',
            description: `Apple pay domain url for validation. Optional. Can be set if there are several domains for brand`,
        },
    },
} as const;
