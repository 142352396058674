/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorResponse } from '../models/ErrorResponse';
import type { SignInManualRequest } from '../models/SignInManualRequest';
import type { SignInPermanentRequest } from '../models/SignInPermanentRequest';
import type { SignInResponse } from '../models/SignInResponse';
import type { SignInSocialRequest } from '../models/SignInSocialRequest';
import type { SignOutRequest } from '../models/SignOutRequest';
import type { SignOutResponse } from '../models/SignOutResponse';
import type { SignUpManualRequest } from '../models/SignUpManualRequest';
import type { SignUpResponse } from '../models/SignUpResponse';
import type { SignUpSocialRequest } from '../models/SignUpSocialRequest';
import type { SignWithGuestRequest } from '../models/SignWithGuestRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthApiService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInApple(
        requestBody: SignInSocialRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_in/apple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param token
     * @param brandName
     * @param rk
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInByToken(
        token: string,
        brandName: string,
        rk: string,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/sign_in/token',
            query: {
                'token': token,
                'brandName': brandName,
                'rk': rk,
            },
        });
    }
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInFacebook(
        requestBody: SignInSocialRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_in/facebook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInGoogle(
        requestBody: SignInSocialRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_in/google',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInManual(
        requestBody: SignInManualRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_in/manual',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signInPermanent(
        requestBody: SignInPermanentRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_in/permanent',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignOutResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signOut(
        requestBody: SignOutRequest,
    ): CancelablePromise<SignOutResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_out',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignUpResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signUpApple(
        requestBody: SignUpSocialRequest,
    ): CancelablePromise<SignUpResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_up/apple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignUpResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signUpFacebook(
        requestBody: SignUpSocialRequest,
    ): CancelablePromise<SignUpResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_up/facebook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignUpResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signUpGoogle(
        requestBody: SignUpSocialRequest,
    ): CancelablePromise<SignUpResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_up/google',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignInResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signUpGuest(
        requestBody: SignWithGuestRequest,
    ): CancelablePromise<SignInResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_up/guest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SignUpResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public signUpManual(
        requestBody: SignUpManualRequest,
    ): CancelablePromise<SignUpResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/sign_up/manual',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
