/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AppleInApp = {
    type: 'all-of',
    description: `If you have this object in reply that's mean apple application purchase processing is enable on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            providerName: {
                type: 'string',
                isRequired: true,
            },
            id: {
                type: 'string',
            },
        },
    }],
} as const;
