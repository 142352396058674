/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProductInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        title: {
            type: 'string',
            isRequired: true,
        },
        route: {
            type: 'string',
            isRequired: true,
        },
        provider: {
            type: 'Enum',
            isRequired: true,
        },
        supplier: {
            type: 'string',
            isRequired: true,
        },
        supplierSearchCode: {
            type: 'string',
            isRequired: true,
        },
        type: {
            type: 'GameTypes',
            isRequired: true,
        },
        mode: {
            type: 'ProductModes',
            isRequired: true,
        },
        unlocked: {
            type: 'boolean',
            isRequired: true,
        },
        hidden: {
            type: 'boolean',
            isRequired: true,
        },
        fromLevel: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        maxLines: {
            type: 'number',
            format: 'int32',
        },
        rank: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        volatility: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        orientation: {
            type: 'Enum',
            isRequired: true,
        },
        moreInfo: {
            type: 'boolean',
            isRequired: true,
        },
        miniMode: {
            type: 'boolean',
        },
        launchDate: {
            type: 'string',
        },
        tags: {
            type: 'ProductInfoTags',
            isRequired: true,
        },
        themes: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        sweepstakeMinBet: {
            type: 'number',
            isRequired: true,
        },
        sweepstakeMaxWinMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        sweepstakeMaxBetMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        scMinBet: {
            type: 'number',
            isRequired: true,
        },
        scMaxWinMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        scMaxBetMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        goldMinBet: {
            type: 'number',
            isRequired: true,
        },
        goldMaxWinMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        goldMaxBetMultiplier: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        active: {
            type: 'boolean',
            isRequired: true,
        },
        animatedTile: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
