/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmOrderRequestBody } from '../models/ConfirmOrderRequestBody';
import type { ConfirmOrderResponseBody } from '../models/ConfirmOrderResponseBody';
import type { CreateAccountPaymentMethodRequestBody } from '../models/CreateAccountPaymentMethodRequestBody';
import type { CreateAccountPaymentMethodResponseBody } from '../models/CreateAccountPaymentMethodResponseBody';
import type { CreateOrderRequestBody } from '../models/CreateOrderRequestBody';
import type { CreateOrderResponseBody } from '../models/CreateOrderResponseBody';
import type { DeletePaymentMethodRequestBody } from '../models/DeletePaymentMethodRequestBody';
import type { DeletePaymentMethodResponseBody } from '../models/DeletePaymentMethodResponseBody';
import type { GetOffersRequestBody } from '../models/GetOffersRequestBody';
import type { GetOffersResponseBody } from '../models/GetOffersResponseBody';
import type { GetPaymentMethodsRequestBody } from '../models/GetPaymentMethodsRequestBody';
import type { GetPaymentMethodsResponseBody } from '../models/GetPaymentMethodsResponseBody';
import type { GetPaymentOrderRequestBody } from '../models/GetPaymentOrderRequestBody';
import type { GetPaymentOrderResponseBody } from '../models/GetPaymentOrderResponseBody';
import type { GetPurchaseHistoryRequestBody } from '../models/GetPurchaseHistoryRequestBody';
import type { GetPurchaseHistoryResponseBody } from '../models/GetPurchaseHistoryResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PurchaseService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Returns list of offers available for purchase.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetOffersResponseBody GetOffersResponse
     * @throws ApiError
     */
    public getOffers(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetOffersRequestBody,
    ): CancelablePromise<GetOffersResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetOffersRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create account payment method.
     * This call is used for creating purchase methods, right now used only for spreedly cards.  After tokenization of card in spreedly iframe pass all card data and pay with token returned in reply.
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CreateAccountPaymentMethodResponseBody CreateAccountPaymentMethodResponseBody
     * @throws ApiError
     */
    public createAccountPaymentMethod(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: CreateAccountPaymentMethodRequestBody,
    ): CancelablePromise<CreateAccountPaymentMethodResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CreateAccountPaymentMethodRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns list purchase methods.
     * Returns list of saved purchase methods that can be reused for subsequent purchases.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPaymentMethodsResponseBody GetPaymentMethodsResponse
     * @throws ApiError
     */
    public getPaymentMethods(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPaymentMethodsRequestBody,
    ): CancelablePromise<GetPaymentMethodsResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPaymentMethodsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete purchase method.
     * Users may want to remove some of their saved methods return in [Get payment methods](#operation/getPaymentMethods). Use this API call for such scenarios. Pass either code or fingerprint.'
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns DeletePaymentMethodResponseBody DeletePaymentMethodResponse
     * @throws ApiError
     */
    public deletePaymentMethod(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: DeletePaymentMethodRequestBody,
    ): CancelablePromise<DeletePaymentMethodResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/DeletePaymentMethodRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Make a purchase.
     * This resource is used to create a payment order. **Important:**  after receiving an error or first player successful purchase, payment providers must be updated: [Get payment providers](#operation/getPaymentServiceInfo)'
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CreateOrderResponseBody CreateOrderResponse
     * @throws ApiError
     */
    public createOrder(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: CreateOrderRequestBody,
    ): CancelablePromise<CreateOrderResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CreateOrderRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Confirm a purchase.
     * Used only for in app purchases and direct fiserv applepay, googlepay.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns ConfirmOrderResponseBody ConfirmOrderResponse
     * @throws ApiError
     */
    public confirmOrder(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: ConfirmOrderRequestBody,
    ): CancelablePromise<ConfirmOrderResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/ConfirmOrderRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get order info.
     * For some integration, in case of redirecting back to our website this call can be used  to check transaction status using transaction id in query parameters. E.G skrill
     *
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPaymentOrderResponseBody GetPaymentOrderResponse
     * @throws ApiError
     */
    public getPaymentOrder(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPaymentOrderRequestBody,
    ): CancelablePromise<GetPaymentOrderResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPaymentOrderRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get purchase history.
     * Returns purchase history based on some filters with pagination.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns GetPurchaseHistoryResponseBody GetPurchaseHistoryResponse
     * @throws ApiError
     */
    public getPurchaseHistory(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: GetPurchaseHistoryRequestBody,
    ): CancelablePromise<GetPurchaseHistoryResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/GetPurchaseHistoryRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
