/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Giveaway } from './Giveaway';
import type { Influencer } from './Influencer';
export type CreateCoinGiveawayResponse = {
    giveaway?: Giveaway;
    influencer?: Influencer;
    rejectionReason?: CreateCoinGiveawayResponse.rejectionReason;
};
export namespace CreateCoinGiveawayResponse {
    export enum rejectionReason {
        NOT_AN_INFLUENCER = 'NOT_AN_INFLUENCER',
        ACTIVE_GIVEAWAY_ALREADY_EXISTS = 'ACTIVE_GIVEAWAY_ALREADY_EXISTS',
        NOT_AUTHORIZED = 'NOT_AUTHORIZED',
        NUMBER_OF_NEW_GIVEAWAYS_REACHED_DAILY_LIMIT = 'NUMBER_OF_NEW_GIVEAWAYS_REACHED_DAILY_LIMIT',
        BUDGET_REACHED_WEEKLY_LIMIT = 'BUDGET_REACHED_WEEKLY_LIMIT',
        UNRECOGNIZED = 'UNRECOGNIZED',
    }
}

