/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CancelRedeemMoneyRequestBody = {
    properties: {
        id: {
            type: 'number',
            description: `Redeem id to cancel.`,
            format: 'int64',
        },
    },
} as const;
