/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Giveaway = {
    properties: {
        code: {
            type: 'string',
            format: 'uuid',
        },
        status: {
            type: 'Enum',
        },
        gcPrize: {
            type: 'number',
        },
        scPrize: {
            type: 'number',
        },
        winnersNumber: {
            type: 'number',
            format: 'int32',
        },
        subscribersNumber: {
            type: 'number',
            format: 'int32',
        },
        createdAt: {
            type: 'number',
            format: 'int64',
        },
        endedAt: {
            type: 'number',
            format: 'int64',
        },
        prize: {
            type: 'dictionary',
            contains: {
                type: 'GiveawayPrize',
            },
        },
    },
} as const;
