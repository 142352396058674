/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetOffersResponseBody = {
    properties: {
        applied: {
            type: 'array',
            contains: {
                type: 'OfferInfo',
            },
        },
        limitAmount: {
            type: 'string',
        },
        limitAvailable: {
            type: 'string',
        },
        limitEnd: {
            type: 'string',
            format: 'date',
        },
        limitPeriod: {
            type: 'string',
        },
        offers: {
            type: 'array',
            contains: {
                type: 'OfferInfo',
            },
        },
        type: {
            type: 'string',
        },
        lastPurchasedOffer: {
            type: 'OfferInfo',
        },
    },
} as const;
