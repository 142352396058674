/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPaymentMetaInfoResponseBody = {
    properties: {
        firstDeposit: {
            type: 'string',
            description: `Date of first successful purchase.`,
            format: 'Date',
        },
        lastDeposit: {
            type: 'string',
            description: `Date of last successful purchase.`,
            format: 'Date',
        },
        withdrawCount: {
            type: 'number',
            description: `Number of successfully confirmed user redemptions.`,
            format: 'int64',
        },
        withdrawTotalAmount: {
            type: 'number',
            description: `Sum of all successfully confirmed user redemptions.`,
        },
        firstWithdraw: {
            type: 'string',
            description: `Date of first successfully confirmed user redemption.`,
            format: 'Date',
        },
    },
} as const;
