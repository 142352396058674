/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Promo = {
    id: string;
    mode: Promo.mode;
};
export namespace Promo {
    export enum mode {
        SC = 'SC',
        GC = 'GC',
    }
}

