/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePayperPaymentMethodRequestBody } from '../models/CreatePayperPaymentMethodRequestBody';
import type { CreatePayperPaymentMethodResponseBody } from '../models/CreatePayperPaymentMethodResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PayperService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Creates a Payper payment method.
     * Create a payment method for Payper and receive a token for subsequent purchases or redeems.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns CreatePayperPaymentMethodResponseBody CreatePayperPaymentMethodResponseBody
     * @throws ApiError
     */
    public createPayperPaymentMethod(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: CreatePayperPaymentMethodRequestBody,
    ): CancelablePromise<CreatePayperPaymentMethodResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/CreatePayperPaymentMethodRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
