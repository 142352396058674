/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountBalanceInfo } from './AccountBalanceInfo';
import type { AccountRestrictions } from './AccountRestrictions';
import type { AccountsMode } from './AccountsMode';
import type { CurrencyPolicies } from './CurrencyPolicies';
import type { InvitationInfo } from './InvitationInfo';
import type { KYCInfo } from './KYCInfo';
import type { KYCStatus } from './KYCStatus';
import type { LegalRule } from './LegalRule';
import type { LevelInfo } from './LevelInfo';
import type { LocationInfo } from './LocationInfo';
import type { PersonalInfo } from './PersonalInfo';
export type AccountInfo = {
    firstName: string;
    lastName: string;
    name?: string;
    username?: string;
    gender?: string;
    birthDate?: string;
    lang?: string;
    email: string;
    realEmail?: string;
    profilePicture: string;
    bot: boolean;
    mode: AccountsMode;
    id: string;
    customerId: string;
    phoneNumber: string;
    guest: boolean;
    admin: boolean;
    test: boolean;
    sweepstakeIntroAccepted: boolean;
    kyc: boolean;
    kycStatus: KYCStatus;
    restrictions: AccountRestrictions;
    sentWelcomeEmail: boolean;
    locked: boolean;
    lockReason?: string;
    invitation: InvitationInfo;
    xpLevel: LevelInfo;
    vipLevel: LevelInfo;
    createdAt?: string;
    sweepstakeIntroAcceptedAt?: string;
    softKycInfo?: KYCInfo;
    kycInfo?: KYCInfo;
    emailVerified: boolean;
    oauth: Array<string>;
    balances?: Array<AccountBalanceInfo>;
    tcVersion: string;
    acceptedTcVersion: string;
    purchaseVipLevel?: string;
    srVersion: string;
    acceptedSrVersion: string;
    ptVersion: string;
    acceptedPtVersion: string;
    ppVersion: string;
    acceptedPpVersion: string;
    tcVersionGC: string;
    acceptedTcVersionGC: string;
    ppVersionGC: string;
    acceptedPpVersionGC: string;
    ptVersionGC: string;
    acceptedPtVersionGC: string;
    tcVersionNonUSGC: string;
    acceptedTcVersionNonUSGC: string;
    ppVersionNonUSGC: string;
    acceptedPpVersionNonUSGC: string;
    autoLockWithdraw: boolean;
    doNotSendEmails: boolean;
    doNotSendPushes: boolean;
    doNotSendSms: boolean;
    legalRules?: Array<LegalRule>;
    legalRuleDate?: string;
    sweepstake: boolean;
    status?: string;
    location: LocationInfo;
    personalInfo: PersonalInfo;
    country?: string;
    freeGoldCoins: boolean;
    locale?: string;
    isInfluencer?: boolean;
    currencyMode?: Record<string, CurrencyPolicies>;
    /**
     * Stored customer unspecified information, external id, picture id, etc. Garbage in, garbage out.
     */
    bucket?: string;
    locationStatus: AccountInfo.locationStatus;
};
export namespace AccountInfo {
    export enum locationStatus {
        OK = 'OK',
        REQUIRED_GPS = 'REQUIRED_GPS',
        RESTRICTED = 'RESTRICTED',
    }
}

