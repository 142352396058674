/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DailyBonusInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        goldAmount: {
            type: 'number',
        },
        sweepstakeAmount: {
            type: 'number',
        },
        fiatAmount: {
            type: 'number',
        },
        externalRewardCode: {
            type: 'string',
        },
        eligible: {
            type: 'boolean',
            isRequired: true,
        },
        offset: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        at: {
            type: 'string',
            isRequired: true,
            format: 'date',
        },
        status: {
            type: 'string',
            isRequired: true,
        },
        time: {
            type: 'string',
        },
    },
} as const;
