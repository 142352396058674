/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemMoneyRequestBody = {
    properties: {
        amount: {
            type: 'number',
            description: `Requested amount of redeems`,
            isRequired: true,
        },
        code: {
            type: 'string',
        },
        currency: {
            type: 'string',
            description: `Currency of redeems`,
            isRequired: true,
        },
        email: {
            type: 'string',
            description: `User email`,
            isRequired: true,
        },
        locked: {
            type: 'boolean',
        },
        method: {
            type: 'WithdrawMethodObject',
            isRequired: true,
        },
        session: {
            type: 'string',
            description: `SEON device fingerprint`,
            isRequired: true,
        },
        type: {
            type: 'string',
        },
    },
} as const;
