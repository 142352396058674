/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { ApplepayService } from './services/ApplepayService';
import { CaptureService } from './services/CaptureService';
import { GooglepayService } from './services/GooglepayService';
import { LimitService } from './services/LimitService';
import { NotificationService } from './services/NotificationService';
import { PaymentCoreService } from './services/PaymentCoreService';
import { PayperService } from './services/PayperService';
import { PurchaseService } from './services/PurchaseService';
import { RedeemService } from './services/RedeemService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Api {
    public readonly applepay: ApplepayService;
    public readonly capture: CaptureService;
    public readonly googlepay: GooglepayService;
    public readonly limit: LimitService;
    public readonly notification: NotificationService;
    public readonly paymentCore: PaymentCoreService;
    public readonly payper: PayperService;
    public readonly purchase: PurchaseService;
    public readonly redeem: RedeemService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.applepay = new ApplepayService(this.request);
        this.capture = new CaptureService(this.request);
        this.googlepay = new GooglepayService(this.request);
        this.limit = new LimitService(this.request);
        this.notification = new NotificationService(this.request);
        this.paymentCore = new PaymentCoreService(this.request);
        this.payper = new PayperService(this.request);
        this.purchase = new PurchaseService(this.request);
        this.redeem = new RedeemService(this.request);
    }
}

