/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CaptureRedeemOptionResponseBody = {
    properties: {
        id: {
            type: 'number',
            format: 'int64',
        },
        kyc: {
            type: 'boolean',
        },
        kycStatus: {
            type: 'KycStatus',
        },
        provider: {
            type: 'string',
        },
        redirectUrl: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
        tmpToken: {
            type: 'string',
            description: `Provider specific metadata, e.g. request signature.`,
        },
        method: {
            type: 'WithdrawMethodObject',
        },
    },
} as const;
