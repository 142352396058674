/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WithdrawMethodType {
    MASSPAY_ACH = 'masspay_ach',
    SKRILL = 'skrill',
    NUVEI_MAZOOMA_ACH = 'nuvei_mazooma_ach',
    PRIZEOUT = 'prizeout',
    TRUSTLY = 'trustly',
    PAYPER = 'payper',
    STANDARD_ACH = 'standard_ach',
    STANDARD_CA_ACH = 'standard_ca_ach',
    STANDARD_BSB_ACH = 'standard_bsb_ach',
}
