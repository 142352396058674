/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ScaAuthenticateData = {
    description: `SCA authenticate data. Include if you have 'ERR_PAYMENT_3DS_REQUIRED' error in [#operation/createOrder]. For spreedly use SpreedlyScaAuthenticateRequest.
    `,
    properties: {
        type: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
