/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $JackpotPreferences = {
    properties: {
        currency: {
            type: 'Currency',
            isRequired: true,
        },
        optional: {
            type: 'boolean',
            isRequired: true,
        },
        minAmount: {
            type: 'number',
            isRequired: true,
        },
        maxAmount: {
            type: 'number',
            isRequired: true,
        },
        jackpots: {
            type: 'array',
            contains: {
                type: 'JackpotInfo',
            },
            isRequired: true,
        },
    },
} as const;
