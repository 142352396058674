/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Platform
 */
export enum platform {
    WEB = 'web',
    ANDROID = 'android',
    IOS = 'ios',
}
