/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OfferInfo = {
    properties: {
        bannerImageUrl: {
            type: 'string',
        },
        code: {
            type: 'string',
        },
        goldFistOffer: {
            type: 'number',
        },
        goldMoney: {
            type: 'number',
        },
        offerType: {
            type: 'Enum',
        },
        popUpImageUrl: {
            type: 'string',
        },
        specialOfferUrl: {
            type: 'string',
        },
        price: {
            type: 'number',
        },
        oldPrice: {
            type: 'number',
        },
        baseOldPrice: {
            type: 'number',
        },
        currency: {
            type: 'string',
        },
        basePrice: {
            type: 'number',
        },
        baseCurrency: {
            type: 'string',
        },
        priority: {
            type: 'number',
        },
        showStickybar: {
            type: 'boolean',
        },
        showTimeLeft: {
            type: 'boolean',
        },
        endAt: {
            type: 'number',
            format: 'int64',
        },
        sweepstakeFirstOffer: {
            type: 'number',
        },
        sweepstakeMoney: {
            type: 'number',
        },
        tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        title: {
            type: 'string',
        },
        vipLevel: {
            type: 'number',
        },
        vipPoints: {
            type: 'number',
        },
        upgradeOffer: {
            type: 'OfferInfo',
        },
        externalRewardCode: {
            type: 'string',
        },
    },
} as const;
