/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GameLaunchResponse = {
    properties: {
        gameUrl: {
            type: 'string',
        },
        sessionId: {
            type: 'string',
        },
        error: {
            type: 'string',
        },
        msg: {
            type: 'string',
        },
    },
} as const;
