/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateCoinGiveawayRequest = {
    properties: {
        winnersNumber: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        gcPrize: {
            type: 'number',
            isRequired: true,
        },
        scPrize: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
