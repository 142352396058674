/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ErrorResponse = {
    properties: {
        errorCode: {
            type: 'string',
        },
        errorReasonCode: {
            type: 'number',
            format: 'int32',
        },
        errorText: {
            type: 'string',
        },
        errorDetails: {
            type: 'dictionary',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
