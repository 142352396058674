/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PurchaseSuccessNotification = {
    description: `This websocket notification is sent after offer is marked as successful [Create order](#operation/createOrder), in most cases CreateOrderResponse is enough to understand status of order. In most cases it is used in cases when player is redirected back from provider checkout page (e.g Nuvei, Skrill) to get notification if order was successful. In cases when transaction is initiated on our website this notification can be ignored.
    `,
    properties: {
        transactionId: {
            type: 'string',
            description: `Unique transaction identifier.`,
            isRequired: true,
            format: 'uuid',
        },
        provider: {
            type: 'string',
            description: `Payment provider that has processed this transaction.`,
            isRequired: true,
        },
        currency: {
            type: 'string',
            description: `Transaction currency.`,
            isRequired: true,
        },
        amount: {
            type: 'number',
            description: `Transaction amount.`,
            isRequired: true,
        },
        balance: {
            type: 'number',
            description: `Current user balance for this currency after this purchase.`,
        },
        firstPurchaseDate: {
            type: 'string',
            description: `Use from transactionDetails`,
            isRequired: true,
            format: 'Date',
        },
        isFirstPurchase: {
            type: 'boolean',
            description: `Use from transactionDetails`,
            isRequired: true,
        },
        city: {
            type: 'string',
            description: `City that was used for transaction processing.`,
        },
        zip: {
            type: 'string',
            description: `Zip code that was used for transaction processing.`,
        },
        billingDescriptor: {
            type: 'string',
            description: `Transaction billing descriptor.`,
        },
        method: {
            type: 'PurchaseMethod',
        },
        transactionDetails: {
            type: 'TransactionDetails',
        },
    },
} as const;
