/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetLiveChatSettingsResponseBody = {
    properties: {
        showLiveChat: {
            type: 'boolean',
            isRequired: true,
        },
        vip: {
            type: 'boolean',
        },
        departments: {
            type: 'ChatDepartment',
        },
        tag: {
            type: 'string',
        },
        tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        external_id: {
            type: 'string',
        },
        purchaseFlowEnabled: {
            type: 'boolean',
            isRequired: true,
        },
        email: {
            type: 'string',
        },
    },
} as const;
