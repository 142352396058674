/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateOrderRequestBody = {
    properties: {
        at: {
            type: 'string',
            description: `Purchase date. May be applicable to some of integrations where purchase created after redirect.`,
            format: 'Date',
        },
        billingAddress: {
            type: 'BillingAddress',
            description: `Billing user details.`,
        },
        cvvEntered: {
            type: 'boolean',
            description: `Applicable to spreedly card routing purchases only.  If new card tokenization or user has entered cvv after receiving \`err_payment_input_cvv\`.`,
        },
        offer: {
            type: 'string',
            description: `Offer code to purchase. Amount will be taken from the offer.`,
        },
        cryptoData: {
            properties: {
                network: {
                    type: 'CryptoNetwork',
                    isRequired: true,
                },
                currency: {
                    type: 'CryptoCurrency',
                    isRequired: true,
                },
            },
        },
        amount: {
            type: 'number',
            description: `Order amount in fiat currency. Must be a greater than 0. Required for deposits, optional for offer purchases.`,
            minimum: 0.01,
        },
        provider: {
            type: 'string',
            description: `Provider used for purchase.`,
            isRequired: true,
        },
        referer: {
            type: 'string',
            description: `Referer URL.`,
        },
        session: {
            type: 'string',
            description: `SEON device fingerprint.`,
        },
        sourceId: {
            type: 'string',
            description: `Routing chain identifier to connect purchases within same routing.  It may happen that during routing of purchase user will be asked to enter cvv. You will get errorCode err_payment_input_cvv. That a signal for you to recache cvv for payment method using spreedly iframe  and resend [Create order](#operation/createOrder) with the same sourceId you've got in response with errorCode err_payment_input_cvv'
            `,
        },
        token: {
            type: 'string',
            description: `Pass payment method token for first time purchases. E.G cardToken, ach.  In case of subsequent purchase pass code from [Get payment methods](#operation/getPaymentMethods)'
            `,
        },
        transactionId: {
            type: 'string',
            description: `Unique identifier of transaction id.`,
            format: 'uuid',
        },
        validationUrl: {
            type: 'string',
            description: `Used for direct fiserv apple pay only.`,
        },
        quickPurchaseSupportedType: {
            type: 'QuickPurchaseSupportedType',
        },
        scaAuthenticateData: {
            type: 'ScaAuthenticateData',
        },
    },
} as const;
