/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InboxNotificationsRefreshedNotification = {
    description: `This websocket notification is sent when user's inbox notifications are updated. For more details see [Get inbox notifications](#operation/getInboxNotifications),
    `,
    properties: {
        notifications: {
            type: 'array',
            contains: {
                type: 'InboxNotificationInfo',
            },
            isRequired: true,
        },
    },
} as const;
