/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignUpResponse = {
    properties: {
        id: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
        mode: {
            type: 'string',
        },
        ttl: {
            type: 'number',
            format: 'int32',
        },
        oauth: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        newAccount: {
            type: 'boolean',
        },
        utmTemplateId: {
            type: 'number',
            format: 'int64',
        },
    },
} as const;
