/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PasswordEnforcementPolicy = {
    properties: {
        min: {
            type: 'number',
            format: 'int32',
        },
        max: {
            type: 'number',
            format: 'int32',
        },
        lowerCase: {
            type: 'number',
            format: 'int32',
        },
        upperCase: {
            type: 'number',
            format: 'int32',
        },
        special: {
            type: 'number',
            format: 'int32',
        },
        digits: {
            type: 'number',
            format: 'int32',
        },
        shouldNotMatchEmail: {
            type: 'boolean',
        },
    },
} as const;
