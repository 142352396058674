/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPaymentServiceInfoResponseBody } from './GetPaymentServiceInfoResponseBody';
/**
 * This notification is triggered as an instruction to ui to update list of purchase and reedem providers by calling [Get payment providers](#operation/getPaymentServiceInfo)
 */
export type UpdatePaymentMethodsNotification = (GetPaymentServiceInfoResponseBody & {
    /**
     * Provider type updated. purchase or redeem
     */
    updatedType?: UpdatePaymentMethodsNotification.updatedType;
});
export namespace UpdatePaymentMethodsNotification {
    /**
     * Provider type updated. purchase or redeem
     */
    export enum updatedType {
        PURCHASE = 'purchase',
        REDEEM = 'redeem',
    }
}

