/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TokenizeApplePayRequestBody = {
    properties: {
        applePayToken: {
            type: 'string',
            description: `Apple pay token from payment authorized callback in iframe.`,
            isRequired: true,
        },
        address1: {
            type: 'string',
            description: `Billing address that comes from apple pay.`,
        },
        address2: {
            type: 'string',
            description: `Billing address that comes from apple pay.`,
        },
        city: {
            type: 'string',
            description: `City that comes from apple pay.`,
        },
        country: {
            type: 'string',
            description: `Country that comes from apple pay.`,
        },
        firstName: {
            type: 'string',
            description: `Cardholder first name that comes from apple pay.`,
        },
        lastName: {
            type: 'string',
            description: `Cardholder last name that comes from apple pay.`,
        },
        postalCode: {
            type: 'string',
            description: `Postal code that comes from apple pay.`,
        },
        stateOrProvince: {
            type: 'string',
            description: `Stare or province that comes from apple pay.`,
        },
    },
} as const;
