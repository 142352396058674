/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetSkipOtpCheckResponseBody = {
    skipOtp: boolean;
    mandatory: boolean;
    /**
     * OTP trigger that was fired
     */
    trigger?: GetSkipOtpCheckResponseBody.trigger;
};
export namespace GetSkipOtpCheckResponseBody {
    /**
     * OTP trigger that was fired
     */
    export enum trigger {
        SIGNED_UP_TRIGGER = 'signed_up_trigger',
        BONUS_TRIGGER = 'bonus_trigger',
        UTM_SOURCE_TRIGGER = 'utm_source_trigger',
    }
}

