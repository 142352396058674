/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPendingRedeemCountResponseBody = {
    properties: {
        count: {
            type: 'number',
            description: `Pending SC redeem count`,
            isRequired: true,
            format: 'int64',
        },
        totalAmount: {
            type: 'number',
            description: `Pending SC redeem amount in local currency`,
            isRequired: true,
        },
        totalBaseAmount: {
            type: 'number',
            description: `Pending SC redeem amount in base currency used during redeem request`,
        },
        fiatRedeemCount: {
            type: 'number',
            description: `Pending fiat redeem count`,
            format: 'int64',
        },
        fiatRedeemTotalAmount: {
            type: 'number',
            description: `Pending fiat redeem amount in local currency`,
        },
        fiatRedeemTotalBaseAmount: {
            type: 'number',
            description: `Pending fiat redeem amount in base currency used during redeem request`,
        },
    },
} as const;
