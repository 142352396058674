/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DeletePaymentMethodRequestBody = {
    properties: {
        code: {
            type: 'string',
            description: `Payment method code.`,
        },
        fingerpint: {
            type: 'string',
            description: `Payment method fingerprint.`,
        },
    },
} as const;
