/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Statuses:
 * * err_ok                    - No error.
 * * err_auth                  - Unauthorized access to resource.
 * * err_system                - Unexpected server error.
 * * err_too_many_request      - You were rate limited.
 * * err_bad_request           - Incorrect parameter check X-Status-Text for more details.
 * * err_duplicate             - Conflict with the current state of the target resource.
 * * err_not_found             - The requested resource could not be found.
 * * err_denied                - Access denied.
 *
 */
export enum Status {
    ERR_OK = 'err_ok',
    ERR_AUTH = 'err_auth',
    ERR_SYSTEM = 'err_system',
    ERR_TOO_MANY_REQUEST = 'err_too_many_request',
    ERR_BAD_REQUEST = 'err_bad_request',
    ERR_DUPLICATE = 'err_duplicate',
    ERR_NOT_FOUND = 'err_not_found',
    ERR_DENIED = 'err_denied',
}
