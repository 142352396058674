/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateOrderResponseBody = {
    properties: {
        at: {
            type: 'string',
            format: 'Date',
        },
        clientToken: {
            type: 'string',
        },
        completeMerchantValidation: {
            type: 'string',
            description: `Used for direct fiserv apple pay only.`,
        },
        cvvEntered: {
            type: 'boolean',
            description: `Echo back request \`cvvEntered\` parameter.`,
        },
        error: {
            type: 'OrderError',
        },
        limitAmount: {
            type: 'string',
        },
        limitAvailable: {
            type: 'string',
        },
        limitEnd: {
            type: 'string',
            format: 'date',
        },
        limitPeriod: {
            type: 'string',
        },
        orderCode: {
            type: 'string',
        },
        paymentData: {
            type: 'string',
        },
        publicKeyBase64: {
            type: 'string',
        },
        redirectUrl: {
            type: 'string',
            description: `URL to redirect. Applicable to integrations where redirect to provider page is needed.`,
        },
        requestKyc: {
            type: 'boolean',
        },
        secure3D: {
            type: 'boolean',
        },
        sourceId: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        supplier: {
            type: 'string',
        },
        tempToken: {
            type: 'string',
        },
        transactionId: {
            type: 'string',
            format: 'uuid',
        },
        type: {
            type: 'string',
        },
        offer: {
            type: 'OfferInfo',
        },
        price: {
            type: 'string',
            description: `Use price from offer (offer.price)`,
        },
        billingDescriptor: {
            type: 'string',
        },
        isFirstDeposit: {
            type: 'boolean',
        },
        firstDepositDate: {
            type: 'string',
            format: 'Date',
        },
        city: {
            type: 'string',
        },
        zip: {
            type: 'string',
        },
        amount: {
            type: 'number',
        },
        currency: {
            type: 'string',
        },
        provider: {
            type: 'string',
        },
        threeDsAuthorizeData: {
            properties: {
                authenticateTxId: {
                    type: 'string',
                    description: `SCA authenticate API call result.
                    `,
                },
                state: {
                    type: 'Enum',
                },
            },
        },
        description: {
            type: 'string',
        },
        method: {
            type: 'PurchaseMethod',
        },
    },
} as const;
