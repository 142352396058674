/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorResponse } from '../models/ErrorResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { ResetPasswordResponse } from '../models/ResetPasswordResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EmailService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @param xTraceId
     * @returns ResetPasswordResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public resetPassword(
        requestBody: ResetPasswordRequest,
        xTraceId?: string,
    ): CancelablePromise<ResetPasswordResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reset-password',
            headers: {
                'X-Trace-Id': xTraceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
