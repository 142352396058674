/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PrizeoutWithdrawMethod = {
    type: 'all-of',
    contains: [{
        type: 'WithdrawMethod',
    }, {
        properties: {
            email: {
                type: 'string',
            },
            nonMonetaryRedeemPolicy: {
                type: 'RedeemMoneyPolicy',
            },
        },
    }],
} as const;
