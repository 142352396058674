/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSkipOtpCheckRequestBody } from '../models/GetSkipOtpCheckRequestBody';
import type { GetSkipOtpCheckResponseBody } from '../models/GetSkipOtpCheckResponseBody';
import type { qPlatform } from '../models/qPlatform';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FraudV2Service {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GetSkipOtpCheckRequest
     * Get skip otp check request
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetSkipOtpCheckResponseBody Get skip otp check
     * @throws ApiError
     */
    public getSkipOtpCheckRequestV2(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: string,
        platform: qPlatform,
        requestBody: GetSkipOtpCheckRequestBody,
        xChk?: string,
    ): CancelablePromise<GetSkipOtpCheckResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetSkipOtpCheckRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
