/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemMoneyResponseBody = {
    properties: {
        id: {
            type: 'number',
            format: 'int64',
        },
        kyc: {
            type: 'boolean',
            isRequired: true,
        },
        kycStatus: {
            type: 'KycStatus',
        },
        provider: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
    },
} as const;
