/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RedeemStatus {
    NEW = 'new',
    PRE_AUTHORIZED = 'pre_authorized',
    CONFIRMED = 'confirmed',
    DECLINED = 'declined',
    FAILED = 'failed',
    CANCELLED = 'cancelled',
    LOCKED = 'locked',
}
