/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SetAccountFraudInfoRequestBody = {
    properties: {
        seonSession: {
            type: 'string',
            description: `New Seon Fingerprint value to be updated`,
            isRequired: true,
        },
    },
} as const;
