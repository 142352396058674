/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Capture3DsPaymentOrderRequestBody } from '../models/Capture3DsPaymentOrderRequestBody';
import type { Capture3DsPaymentOrderResponseBody } from '../models/Capture3DsPaymentOrderResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CaptureService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Start capture 3ds payment.
     * Capturing of 3ds payment is made when 3ds challenge is completed
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns Capture3DsPaymentOrderResponseBody Capture3DSPaymentOrderResponse
     * @throws ApiError
     */
    public capture3DsPayment(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: Capture3DsPaymentOrderRequestBody,
    ): CancelablePromise<Capture3DsPaymentOrderResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/Capture3DsPaymentOrderRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
