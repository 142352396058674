/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateInboxNotificationRequestBody = {
    properties: {
        notificationToken: {
            type: 'string',
            description: `Token of notification returned in [Get inbox notifications](#operation/getInboxNotifications)`,
            isRequired: true,
        },
        status: {
            type: 'Enum',
            isRequired: true,
        },
    },
} as const;
