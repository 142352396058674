/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AcceptSocialMediaRewardResponse = {
    properties: {
        gcAmount: {
            type: 'string',
        },
        scAmount: {
            type: 'string',
        },
    },
} as const;
