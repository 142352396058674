/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccountBalanceInfo = {
    properties: {
        currency: {
            type: 'Currency',
            isRequired: true,
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        redeemable: {
            type: 'number',
            isRequired: true,
        },
        unplayed: {
            type: 'number',
            isRequired: true,
        },
        pendingWithdraw: {
            type: 'number',
            isRequired: true,
        },
        owed: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
