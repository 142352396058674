/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JackpotWinnerInfo } from './JackpotWinnerInfo';
export type JackpotInfo = {
    code: JackpotInfo.code;
    prevAmount: number;
    amount: number;
    updatedAt: number;
    lastWinAt: number;
    endAt: string;
    lastWinner?: JackpotWinnerInfo;
};
export namespace JackpotInfo {
    export enum code {
        MINI = 'mini',
        MINOR = 'minor',
        MAJOR = 'major',
        GRAND = 'grand',
    }
}

