/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateAccountFreeSpinRequest } from '../models/ActivateAccountFreeSpinRequest';
import type { ActivateAccountFreeSpinResponse } from '../models/ActivateAccountFreeSpinResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { GetAccountFreeSpinsRequest } from '../models/GetAccountFreeSpinsRequest';
import type { GetAccountFreeSpinsResponse } from '../models/GetAccountFreeSpinsResponse';
import type { qBrandName } from '../models/qBrandName';
import type { qPlatform } from '../models/qPlatform';
import type { xMessageId } from '../models/xMessageId';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GameHubService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns ActivateAccountFreeSpinResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public activateAccountFreeSpin(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: ActivateAccountFreeSpinRequest,
        xChk?: string,
    ): CancelablePromise<ActivateAccountFreeSpinResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/ActivateAccountFreeSpinRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xMessageId Uniq Id of request in UUID format
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @param xChk Enable additional request validation (anything or empty)
     * @returns GetAccountFreeSpinsResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public getAccountFreeSpins(
        xMessageId: xMessageId,
        xTimestamp: number,
        brandName: qBrandName,
        platform: qPlatform,
        requestBody: GetAccountFreeSpinsRequest,
        xChk?: string,
    ): CancelablePromise<GetAccountFreeSpinsResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/dispatch/GetAccountFreeSpinsRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
                'X-Chk': xChk,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
