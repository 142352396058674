/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LegalRule = {
    properties: {
        setting: {
            type: 'LegalRuleSettings',
            isRequired: true,
        },
        text: {
            type: 'string',
            isRequired: true,
        },
        type: {
            type: 'RuleType',
            isRequired: true,
        },
    },
} as const;
