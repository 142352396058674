/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Prizeout = {
    type: 'all-of',
    description: `Non-monetary redeem provider. If you have this object in reply that's mean prizeout is enabled on backend side. For initialization prizeout widget we need to pass id and key to the  window.prizeoutSDK.init({...., publisher: {..., id: prizeoutId: id, apikey:key},})
    `,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            id: {
                type: 'string',
                description: `ID to pass to prizeout widget`,
            },
            key: {
                type: 'string',
                description: `API key to pass to prizeout widget`,
            },
        },
    }],
} as const;
