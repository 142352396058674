/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Currency {
    GC = 'GC',
    SC = 'SC',
    USD = 'USD',
    CAD = 'CAD',
    AUD = 'AUD',
    GBP = 'GBP',
}
