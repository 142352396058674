/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LevelInfo = {
    properties: {
        level: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        current: {
            type: 'number',
            isRequired: true,
        },
        threshold: {
            type: 'number',
            isRequired: true,
        },
        range: {
            type: 'number',
            isRequired: true,
        },
        category: {
            type: 'Enum',
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        currentMonthPoints: {
            type: 'string',
        },
        currentYearPoints: {
            type: 'string',
        },
        baseMonthlyLevel: {
            type: 'number',
            format: 'int32',
        },
        nextMonthlyLevel: {
            type: 'number',
            format: 'int32',
        },
        monthlyLevelProgress: {
            type: 'string',
        },
        baseAnnualLevel: {
            type: 'number',
            format: 'int32',
        },
        nextAnnualLevel: {
            type: 'number',
            format: 'int32',
        },
        annualLevelProgress: {
            type: 'string',
        },
        levelValidUntil: {
            type: 'string',
            format: 'date-time',
        },
        levelType: {
            type: 'string',
        },
        remainingAmountPoints: {
            type: 'string',
        },
    },
} as const;
