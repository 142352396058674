/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetWithdrawMethodsResponseBody = {
    properties: {
        methods: {
            type: 'array',
            contains: {
                type: 'WithdrawMethodObject',
            },
            isRequired: true,
        },
        policies: {
            properties: {
                masspayAch: {
                    type: 'WithdrawMethodPolicy',
                },
                mazoomaAch: {
                    type: 'WithdrawMethodPolicy',
                },
                prizeout: {
                    type: 'WithdrawMethodPolicy',
                },
                trustly: {
                    type: 'WithdrawMethodPolicy',
                },
                skrill: {
                    type: 'WithdrawMethodPolicy',
                },
                payper: {
                    type: 'WithdrawMethodPolicy',
                },
                standardAch: {
                    type: 'WithdrawMethodPolicy',
                },
                standardCanadaAch: {
                    type: 'WithdrawMethodPolicy',
                },
                standardBsbAch: {
                    type: 'WithdrawMethodPolicy',
                },
            },
        },
    },
} as const;
