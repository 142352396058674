/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateRange } from './DateRange';
import type { PageRequest } from './PageRequest';
export type GetPurchaseHistoryRequestBody = {
    dateRange?: DateRange;
    /**
     * The optional page details. If not specified, the API returns purchases with default pagination
     */
    pageRequest?: PageRequest;
    /**
     * Type of the order (for now is offer purchase or deposit). Lists all if the type is not specified
     */
    orderType?: GetPurchaseHistoryRequestBody.orderType;
};
export namespace GetPurchaseHistoryRequestBody {
    /**
     * Type of the order (for now is offer purchase or deposit). Lists all if the type is not specified
     */
    export enum orderType {
        OFFER_PURCHASE = 'offer_purchase',
        DEPOSIT = 'deposit',
    }
}

