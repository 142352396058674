/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CurrencyPolicies = {
    mode?: CurrencyPolicies.mode;
};
export namespace CurrencyPolicies {
    export enum mode {
        DISABLED = 'DISABLED',
        ENABLED = 'ENABLED',
        PARTIAL = 'PARTIAL',
    }
}

