/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPurchaseHistoryResponseBody = {
    properties: {
        purchases: {
            type: 'array',
            contains: {
                type: 'PurchaseItem',
            },
            isRequired: true,
        },
        pagination: {
            type: 'Pagination',
            isRequired: true,
        },
    },
} as const;
