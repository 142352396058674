/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CardData = {
    properties: {
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        firstSixDigits: {
            type: 'string',
            isRequired: true,
        },
        firstEightDigits: {
            type: 'string',
        },
        lastFour: {
            type: 'string',
            isRequired: true,
        },
        month: {
            type: 'number',
            isRequired: true,
            format: 'int32',
        },
        year: {
            type: 'number',
            description: `4 digits`,
            isRequired: true,
            format: 'int32',
        },
        cardType: {
            type: 'string',
            description: `visa, master`,
            isRequired: true,
        },
        fingerprint: {
            type: 'string',
            description: `unique card identifier`,
            isRequired: true,
        },
        billingAddress: {
            type: 'BillingAddress',
            isRequired: true,
        },
    },
} as const;
