/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Used to track source of quick purchase.
 */
export enum QuickPurchaseSupportedType {
    QUICK_PURCHASE_GET_COINS = 'quick_purchase_get_coins',
    QUICK_PURCHASE = 'quick_purchase',
    QUICK_DEPOSIT = 'quick_deposit',
}
