/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPaymentServiceInfoResponseBody = {
    properties: {
        thirdPartyCheckEnabled: {
            type: 'boolean',
            description: `AB testing. Indicates whether 3rd part card check is enabled.  If enabled verify cardholder name matches with account first last name from personal info.
            `,
        },
        softKycRequiredEnabled: {
            type: 'boolean',
            description: `AB testing. Indicates whether user need to complete soft kyc data before any purchase or redeem`,
        },
        cardRegistrationModify: {
            type: 'boolean',
            description: `AB testing. Indicates for modify card registration first time and Subsequent Purchasers`,
        },
        softKycAutoCompleteAddress: {
            type: 'boolean',
            description: `AB testing. Indicates whether to apply soft kyc autocomplete address.`,
        },
        purchaseProviders: {
            properties: {
                appleInApp: {
                    type: 'AppleInApp',
                },
                androidInApp: {
                    type: 'AndroidInApp',
                },
                fiserv: {
                    type: 'Fiserv',
                },
                fiservApplePay: {
                    type: 'FiservApplePay',
                },
                fiservGooglePay: {
                    type: 'FiservGooglePay',
                },
                nuvei: {
                    type: 'Nuvei',
                },
                trustly: {
                    type: 'Trustly',
                },
                skrill: {
                    type: 'Skrill',
                },
                spreedly: {
                    type: 'Spreedly',
                },
                spreedlyApplePay: {
                    type: 'SpreedlyApplePay',
                },
                spreedlyGooglePay: {
                    type: 'SpreedlyGooglePay',
                },
                payper: {
                    type: 'Payper',
                },
                crypto: {
                    type: 'Crypto',
                },
            },
        },
        withdrawProviders: {
            properties: {
                masspay: {
                    type: 'Masspay',
                },
                nuvei: {
                    type: 'Nuvei',
                },
                trustly: {
                    type: 'Trustly',
                },
                prizeout: {
                    type: 'Prizeout',
                },
                skrill: {
                    type: 'Skrill',
                },
                payper: {
                    type: 'Payper',
                },
                standardAch: {
                    type: 'StandardAch',
                },
            },
        },
        type: {
            type: 'string',
        },
    },
} as const;
