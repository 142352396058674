/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LevelInfo = {
    level: number;
    current: number;
    threshold: number;
    range: number;
    category: LevelInfo.category;
    name: string;
    currentMonthPoints?: string;
    currentYearPoints?: string;
    baseMonthlyLevel?: number;
    nextMonthlyLevel?: number;
    monthlyLevelProgress?: string;
    baseAnnualLevel?: number;
    nextAnnualLevel?: number;
    annualLevelProgress?: string;
    levelValidUntil?: string;
    levelType?: string;
    remainingAmountPoints?: string;
};
export namespace LevelInfo {
    export enum category {
        FREE_LEVEL = 'free_level',
        VIP_LEVEL = 'vip_level',
    }
}

