/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InboxNotificationType } from './InboxNotificationType';
/**
 * Details on inbox notification event
 */
export type InboxNotificationInfo = {
    type: InboxNotificationType;
    /**
     * Unique identifier. Used in [Update notification](#operation/updateInboxNotification)
     */
    notificationToken: string;
    status: InboxNotificationInfo.status;
    createdAt: number;
    expiresAt?: number;
};
export namespace InboxNotificationInfo {
    export enum status {
        UNREAD = 'unread',
        READ = 'read',
        CLAIMED = 'claimed',
        EXPIRED = 'expired',
        REMOVED = 'removed',
    }
}

