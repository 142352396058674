/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RedeemUserStatus {
    PENDING = 'pending',
    PROCESSED = 'processed',
    CANCELED = 'canceled',
    FAILED = 'failed',
}
