/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $KYCInfo = {
    properties: {
        country: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        city: {
            type: 'string',
            isRequired: true,
        },
        zip: {
            type: 'string',
            isRequired: true,
        },
        address: {
            type: 'string',
            isRequired: true,
        },
        address2: {
            type: 'string',
        },
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
