/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InboxNotification = {
    id?: string;
    title?: string;
    message: string;
    categoryCode?: string;
    status?: InboxNotification.status;
    createdAt?: number;
};
export namespace InboxNotification {
    export enum status {
        UNREAD = 'unread',
    }
}

