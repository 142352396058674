/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum KycStatus {
    INITIAL = 'initial',
    IN_REVIEW = 'in_review',
    CONFIRMED = 'confirmed',
    ID_CONFIRMED = 'id_confirmed',
    DOC_REVIEW = 'doc_review',
    DOC_DECLINED = 'doc_declined',
    SESSION_EXPIRED = 'session_expired',
    DECLINED = 'declined',
    BLOCKED = 'blocked',
}
