/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CryptoServiceInfoItem = {
    properties: {
        currency: {
            type: 'CryptoCurrencyData',
            isRequired: true,
        },
        minLimitInBaseCurrency: {
            type: 'number',
        },
        rateToBaseCurrency: {
            type: 'number',
        },
        networks: {
            type: 'array',
            contains: {
                type: 'CryptoNetworkData',
            },
            isRequired: true,
        },
    },
} as const;
