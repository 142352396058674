/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PurchaseItem = {
    properties: {
        transactionId: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        dateTime: {
            type: 'string',
            description: `The creation or completion date depending on the purchase status.`,
            isRequired: true,
            format: 'date-time',
        },
        balanceChange: {
            type: 'array',
            contains: {
                type: 'BalanceChange',
            },
        },
        price: {
            type: 'array',
            contains: {
                type: 'Price',
            },
        },
        method: {
            type: 'PurchaseMethod',
        },
        cryptoPurchaseData: {
            type: 'CryptoPurchaseData',
        },
    },
} as const;
