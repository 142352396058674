/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SkrillPaymentMethod = {
    type: 'all-of',
    contains: [{
        type: 'PaymentMethod',
    }, {
        properties: {
            email: {
                type: 'string',
            },
            lastUsageAt: {
                type: 'number',
                format: 'int64',
            },
        },
    }],
} as const;
