/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Mode {
    DT = 'dt',
    GC = 'gc',
    FL = 'fl',
    SC = 'sc',
    SCPR = 'scpr',
    SCPRWB = 'scprwb',
}
