/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemProviderSettings = {
    properties: {
        providerName: {
            type: 'string',
        },
        withdrawMethodName: {
            type: 'string',
            description: `Name of withdraw method related to provider, matches the type in response  [Get withdraw methods](#operation/getWithdrawMethods)'
            `,
        },
        merchantName: {
            type: 'string',
            description: `Access ID used for iframe initialization. Provider specific field.`,
        },
        merchantId: {
            type: 'string',
            description: `Merchant ID used for iframe initialization. Provider specific field.`,
        },
        apiKey: {
            type: 'string',
            description: `API key to pass to widget. Provider specific field.`,
        },
        notificationUrl: {
            type: 'string',
            description: `Notification URL used for iframe initialization. Provider specific field.`,
        },
        limitPolicy: {
            type: 'RedeemLimitPolicy',
        },
    },
} as const;
