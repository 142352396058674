/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BillingAddress = {
    properties: {
        city: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        dateOfBirth: {
            type: 'string',
        },
        firstName: {
            type: 'string',
        },
        houseNumberOrName: {
            type: 'string',
        },
        lastName: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
            isRequired: true,
        },
        stateOrProvince: {
            type: 'string',
        },
        street: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
