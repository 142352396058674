/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Operation status
 */
export enum xStatus {
    ERR_OK = 'err_ok',
    ERR_AUTH = 'err_auth',
    ERR_SYSTEM = 'err_system',
    ERR_TOO_MANY_REQUEST = 'err_too_many_request',
    ERR_BAD_REQUEST = 'err_bad_request',
    ERR_PAYMENT = 'err_payment',
}
