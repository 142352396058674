/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SaveChatEventRequestBody = {
    /**
     * Chat event type
     */
    event: SaveChatEventRequestBody.event;
    /**
     * Chat flow type
     */
    flow: SaveChatEventRequestBody.flow;
};
export namespace SaveChatEventRequestBody {
    /**
     * Chat event type
     */
    export enum event {
        CHAT_SHOWN = 'chat_shown',
        CHAT_OPENED = 'chat_opened',
    }
    /**
     * Chat flow type
     */
    export enum flow {
        PURCHASE_FLOW = 'purchase_flow',
        REDEEM_FLOW = 'redeem_flow',
    }
}

