/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Pagination = {
    properties: {
        totalRecords: {
            type: 'number',
            format: 'int32',
        },
        currentPage: {
            type: 'number',
            format: 'int32',
        },
        totalPages: {
            type: 'number',
            format: 'int32',
        },
        hasNextPage: {
            type: 'boolean',
        },
        hasPrevPage: {
            type: 'boolean',
        },
    },
} as const;
