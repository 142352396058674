/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteAccountRequest } from '../models/DeleteAccountRequest';
import type { DeleteAccountResponse } from '../models/DeleteAccountResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AccountApiService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns DeleteAccountResponse
     * @returns ErrorResponse
     * @throws ApiError
     */
    public deleteAccount(
        requestBody: DeleteAccountRequest,
    ): CancelablePromise<DeleteAccountResponse | ErrorResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/account_delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
