/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ActivateAccountFreeSpinResponse = {
    status?: ActivateAccountFreeSpinResponse.status;
};
export namespace ActivateAccountFreeSpinResponse {
    export enum status {
        SUCCESS = 'SUCCESS',
        NOT_FOUND = 'NOT_FOUND',
        ALREADY_ACTIVE = 'ALREADY_ACTIVE',
        NOT_ALLOWED = 'NOT_ALLOWED',
        ERROR = 'ERROR',
    }
}

