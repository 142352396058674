/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CaptureRedeemOptionRequestBody = {
    properties: {
        at: {
            type: 'string',
            format: 'Date',
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        currency: {
            type: 'string',
            isRequired: true,
        },
        provider: {
            type: 'string',
            isRequired: true,
        },
        referer: {
            type: 'string',
            isRequired: true,
        },
        type: {
            type: 'string',
        },
    },
} as const;
