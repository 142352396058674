/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Fiserv = {
    type: 'all-of',
    description: `If you have this object in reply that's mean fiserv card processing is enable on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }, {
        properties: {
            providerName: {
                type: 'string',
                isRequired: true,
            },
            id: {
                type: 'string',
                description: `Fiserv client id, not used for iframe initialization`,
                isRequired: true,
            },
        },
    }],
} as const;
