/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OptOutArbitrationResponse = {
    rejectionReason: OptOutArbitrationResponse.rejectionReason;
};
export namespace OptOutArbitrationResponse {
    export enum rejectionReason {
        ALREADY_OPTED_OUT = 'ALREADY_OPTED_OUT',
        SIGN_UP_IS_OLDER_THAN_30_DAYS = 'SIGN_UP_IS_OLDER_THAN_30_DAYS',
    }
}

