/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConfirmOrderResponseBody = {
    properties: {
        errorMsg: {
            type: 'string',
        },
        mode: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        token: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
        offer: {
            type: 'OfferInfo',
        },
        price: {
            type: 'string',
        },
        billingDescriptor: {
            type: 'string',
        },
        isFirstDeposit: {
            type: 'boolean',
        },
        firstDepositDate: {
            type: 'string',
            format: 'Date',
        },
        city: {
            type: 'string',
        },
        zip: {
            type: 'string',
        },
        amount: {
            type: 'number',
        },
        currency: {
            type: 'string',
        },
        provider: {
            type: 'string',
        },
    },
} as const;
