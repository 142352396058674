/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetWithdrawMethodsRequestBody = {
    type: 'dictionary',
    contains: {
        properties: {
        },
    },
} as const;
