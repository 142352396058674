/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RedeemItem = {
    properties: {
        amount: {
            type: 'number',
        },
        createdAt: {
            type: 'string',
            format: 'Date',
        },
        currency: {
            type: 'Currency',
            isRequired: true,
        },
        id: {
            type: 'number',
            isRequired: true,
            format: 'int64',
        },
        provider: {
            type: 'string',
            isRequired: true,
        },
        status: {
            type: 'RedeemStatus',
            isRequired: true,
        },
        redeemStatus: {
            type: 'RedeemUserStatus',
        },
        paymentDetails: {
            type: 'string',
        },
    },
} as const;
