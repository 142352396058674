/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdatePaymentMethodsNotification = {
    type: 'all-of',
    description: `This notification is triggered as an instruction to ui to update list of purchase and reedem providers by calling [Get payment providers](#operation/getPaymentServiceInfo)`,
    contains: [{
        type: 'GetPaymentServiceInfoResponseBody',
    }, {
        properties: {
            updatedType: {
                type: 'Enum',
            },
        },
    }],
} as const;
