import { gateway, gatewayError } from '@patrianna/gateway'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import * as Sentry from '@sentry/nextjs'

import { openDialog } from 'store/modules/dialog/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { createApiClient } from 'temp/api'
import type { ValidateResponse } from 'temp/api/types'
import { createApiClient as createFraudApiClient } from 'temp/fraud'
import { createApiClient as createPaymentApiClient } from 'temp/payment'

const gatewayInstance = gateway.create({
  baseURL: process.env.GATEWAY,
  baseFetchURL: process.env.SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
})

export const gatewayErrorInstance = gatewayError.setupActions({ openDialog, openSnackbar })

export default gatewayInstance

export const api = createApiClient({
  url: isBrowser() ? process.env.GATEWAY : process.env.SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
  urlSigningsKey: process.env.URL_SIGNING_KEY,
  userAgent: isBrowser() ? undefined : process.env.VERCEL_USER_AGENT || 'VERCEL_USER_AGENT undefined',
  onValidationError: (payload: ValidateResponse) => {
    Sentry.captureException(new Error(`[API VALIDATION ERROR]: ${payload?.request}`), {
      level: 'warning',
      extra: { errors: payload?.errors },
    })
    if (process.env.PROFILE === 'dev') {
      console.warn(`[API VALIDATION ERROR]: ${payload?.request}`, payload?.errors)
    }
  },
})

export const fraud = createFraudApiClient({
  url: isBrowser() ? process.env.FRAUD_GATEWAY : process.env.FRAUD_SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
  urlSigningsKey: process.env.URL_SIGNING_KEY,
  userAgent: isBrowser() ? undefined : process.env.VERCEL_USER_AGENT || 'VERCEL_USER_AGENT undefined',
  onValidationError: (payload: ValidateResponse) => {
    Sentry.captureException(new Error(`[API VALIDATION ERROR]: ${payload?.request}`), {
      level: 'warning',
      extra: { errors: payload?.errors },
    })
    if (process.env.PROFILE === 'dev') {
      console.warn(`[API VALIDATION ERROR]: ${payload?.request}`, payload?.errors)
    }
  },
})

export const payment = createPaymentApiClient({
  url: isBrowser() ? process.env.PAYMENT_GATEWAY : process.env.PAYMENT_SERVER_FETCH_URL,
  brandName: process.env.BRAND_NAME,
  platform: 'web',
  urlSigningsKey: process.env.URL_SIGNING_KEY,
  userAgent: isBrowser() ? undefined : process.env.VERCEL_USER_AGENT || 'VERCEL_USER_AGENT undefined',
  onValidationError: (payload: ValidateResponse) => {
    Sentry.captureException(new Error(`[API VALIDATION ERROR]: ${payload?.request}`), {
      level: 'warning',
      extra: { errors: payload?.errors },
    })
    if (process.env.PROFILE === 'dev') {
      console.warn(`[API VALIDATION ERROR]: ${payload?.request}`, payload?.errors)
    }
  },
})
