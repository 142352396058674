/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WheelOfWinnersInfo = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        sections: {
            type: 'array',
            contains: {
                type: 'WheelOfWinnersSection',
            },
        },
        nextSpinAt: {
            type: 'string',
            format: 'date-time',
        },
        spins: {
            type: 'number',
            format: 'int32',
        },
    },
} as const;
