/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Payper = {
    type: 'all-of',
    description: `E-Transfer provider. If you have this object in reply that's mean payper is enabled on backend side.`,
    contains: [{
        type: 'PaymentProvider',
    }],
} as const;
