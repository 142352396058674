/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OptInGiveawayResponse = {
    rejectionReason?: OptInGiveawayResponse.rejectionReason;
};
export namespace OptInGiveawayResponse {
    export enum rejectionReason {
        GIVEAWAY_DOES_NOT_EXIST_OR_INACTIVE = 'GIVEAWAY_DOES_NOT_EXIST_OR_INACTIVE',
        ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
        OWNER_CAN_NOT_SUBSCRIBE = 'OWNER_CAN_NOT_SUBSCRIBE',
        NOT_PLAYABLE_ACCOUNT = 'NOT_PLAYABLE_ACCOUNT',
        UNRECOGNIZED = 'UNRECOGNIZED',
    }
}

