/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenizeGooglePayRequestBody } from '../models/TokenizeGooglePayRequestBody';
import type { TokenizeGooglePayResponseBody } from '../models/TokenizeGooglePayResponseBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GooglepayService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Googlepay method tokenization.
     * @param xMessageId UUID of original request
     * @param xTimestamp Unix request timestamp
     * @param brandName Brand name
     * @param platform Platform
     * @param requestBody
     * @returns TokenizeGooglePayResponseBody TokenizeGooglePayResponse
     * @throws ApiError
     */
    public tokenizeGooglePay(
        xMessageId: string,
        xTimestamp: number,
        brandName: string,
        platform: 'web' | 'android' | 'ios',
        requestBody: TokenizeGooglePayRequestBody,
    ): CancelablePromise<TokenizeGooglePayResponseBody> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dispatch/TokenizeGooglePayRequest',
            headers: {
                'X-Message-Id': xMessageId,
                'X-Timestamp': xTimestamp,
            },
            query: {
                'brandName': brandName,
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
