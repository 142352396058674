/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TokenizeGooglePayRequestBody = {
    properties: {
        googlePayToken: {
            type: 'string',
            description: `Google pay token from google pay lib(on load payment data).`,
        },
        type: {
            type: 'string',
        },
        zip: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
        stateOrProvince: {
            type: 'string',
        },
        address1: {
            type: 'string',
        },
        address2: {
            type: 'string',
        },
        fullName: {
            type: 'string',
        },
    },
} as const;
